import { BrowserModule } from "@angular/platform-browser";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { InputsModule } from "@progress/kendo-angular-inputs";
import { AppComponent } from "./app.component";
import {
  MatDatepickerModule,
  MatInputModule,
  MatListModule,
  MatNativeDateModule,
  MatCheckboxModule,
  MatSlideToggleModule,
} from "@angular/material";
import { AppRoutingModule } from "./app-routing.module";
import { NgxMaskModule, IConfig } from "ngx-mask";
import { ButtonsModule } from "@progress/kendo-angular-buttons";
import { AgGridModule } from "ag-grid-angular";
import "ag-grid-enterprise";
import { DropDownsModule } from "@progress/kendo-angular-dropdowns";
import { TextMaskModule } from "angular2-text-mask";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { UploadModule } from "@progress/kendo-angular-upload";
import { UploadInterceptor } from "./app.component";
import { Safe, commonhelper } from "./common/commonhelper";
import { NotificationModule } from "@progress/kendo-angular-notification";
// import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { NgBusyModule } from "ng-busy";
import { OasLoginComponentComponent } from "./oas-login-component/oas-login-component.component";
import { OasSignupComponentComponent } from "./oas-signup-component/oas-signup-component.component";
import { OasMainDashboardComponentComponent } from "./oas-main-dashboard-component/oas-main-dashboard-component.component";
import { EmployeeInfoComponentComponent } from "./employee-info-component/employee-info-component.component";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { AddTaskInformationComponent } from "./add-task-information/add-task-information.component";
import { ViewTaskInformationComponent } from "./view-task-information/view-task-information.component";
import { ViewFullTaskInformationComponent } from "./view-full-task-information/view-full-task-information.component";
import { EmployeeDashboardViewComponent } from "./employee-dashboard-view/employee-dashboard-view.component";
import { ProjectHierarchyInfoComponent } from "./project-hierarchy-info/project-hierarchy-info.component";
import { CreateNewTaskComponentComponent } from "./create-new-task-component/create-new-task-component.component";
// import { NgxEditorModule } from 'ngx-editor';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AngularFontAwesomeModule } from "angular-font-awesome";
import { commonHelperService } from "./common/common.service";
import { AuthenticationService } from "./services/authentication";
import { DatePickerModule } from "@progress/kendo-angular-dateinputs";
import { QuillModule } from "ngx-quill";

// import Quill from 'quill'
// import QuillImageDropAndPaste from 'quill-image-drop-and-paste'

//import  base64StringToBlob  from 'blob-util'
// import Quill from 'quill';
import { ContextMenuModule, MenuModule } from "@progress/kendo-angular-menu";

import { DialogsModule, DialogService } from "@progress/kendo-angular-dialog";

import { TaskService } from "./services/task.service";
import { ViewFullImageComponentComponent } from "./view-full-image-component/view-full-image-component.component";
import { CommonMessageInfoComponent } from "./common-message-info/common-message-info.component";
import { HttpRequestInterceptor } from "./http-request-interceptor";
import { LeftNavService } from "./common/Leftnav.service";
import { AddProjectsInformationComponent } from "./add-projects-information/add-projects-information.component";
import { EditTaskInformationComponent } from "./edit-task-information/edit-task-information.component";
import { TaskCustomizationInformationComponent } from "./task-customization-information/task-customization-information.component";
// tslint:disable-next-line:max-line-length
import { AddTaskCustomizationInformationComponent } from "./add-task-customization-information/add-task-customization-information.component";
import { PraticesListComponent } from "./pratices-list/pratices-list.component";
import { LayoutModule } from "@progress/kendo-angular-layout";
// tslint:disable-next-line:max-line-length
import { AddTaskCustomizationBasedOnEmailAddressComponent } from "./add-task-customization-based-on-email-address/add-task-customization-based-on-email-address.component";
// tslint:disable-next-line:max-line-length
import { EditTaskInformationBasedOnEmailAddressComponent } from "./edit-task-information-based-on-email-address/edit-task-information-based-on-email-address.component";
// tslint:disable-next-line:max-line-length
import { EditTaskCustomizationBasedOnUsersComponent } from "./edit-task-customization-based-on-users/edit-task-customization-based-on-users.component";
import { PracticeUsersListComponent } from "./practice-users-list/practice-users-list.component";
import { UncustomizedTaskListComponent } from "./uncustomized-task-list/uncustomized-task-list.component";
import { EdituncustomizedtasklistComponent } from "./edituncustomizedtasklist/edituncustomizedtasklist.component";
import { IntegerDirective } from "./integer.directive";
import { ViewTaskInformationComponentMainComponent } from "./view-task-information-component-main/view-task-information-component-main.component";
import { EnterMultipleEmailAddressPopupComponent } from "./enter-multiple-email-address-popup/enter-multiple-email-address-popup.component";
import { TrimWhitespacesDirective } from "./shared/trim-whitespaces.directive";
import { PdfAttachmentsDisplayComponent } from "./pdf-attachments-display/pdf-attachments-display.component";
import { ResponsiblepersonUsersListComponent } from "./responsibleperson-users-list/responsibleperson-users-list.component";
import { UncustomizedtaskCommentsComponent } from "./uncustomizedtask-comments/uncustomizedtask-comments.component";
import { TaskCommentAttachmentsCellrendererComponent } from "./task-comment-attachments-cellrenderer/task-comment-attachments-cellrenderer.component";
import { TaskCommentContentCellrendererComponent } from "./task-comment-content-cellrenderer/task-comment-content-cellrenderer.component";
import { SelectModulePopupComponentComponent } from "./select-module-popup-component/select-module-popup-component.component";
import { ChangePasswordPopupComponentComponent } from "./change-password-popup-component/change-password-popup-component.component";
import { AdditionalParticipantAndObserversPopupComponentComponent } from "./additional-participant-and-observers-popup-component/additional-participant-and-observers-popup-component.component";
import { PracticeusersinformationcomponentComponent } from "./practiceusersinformationcomponent/practiceusersinformationcomponent.component";
import { DeleteSelectedPersonRendererComponent } from "./delete-selected-person-renderer/delete-selected-person-renderer.component";
import { TestingComponent } from "./testing/testing.component";
import { AllTasksSummaryByForwardedToMeBucketComponent } from "./all-tasks-summary-by-forwarded-to-me-bucket/all-tasks-summary-by-forwarded-to-me-bucket.component";
import { ReportByResponsibleEmployeeComponent } from "./report-by-responsible-employee/report-by-responsible-employee.component";
import { AccessInformationComponent } from "./access-information/access-information.component";
import { ViewAccessInformationPopupComponent } from "./view-access-information-popup/view-access-information-popup.component";
import { AddEmployeeInfoPopupComponent } from "./add-employee-info-popup/add-employee-info-popup.component";
import { ContactComponent } from "./contact/contact.component";
import { EditAccessDetailsPopupComponent } from "./edit-access-details-popup/edit-access-details-popup.component";
import { DateInputsModule } from "@progress/kendo-angular-dateinputs";
import { MonthSummaryReportComponent } from "./month-summary-report/month-summary-report.component";
import { SelectTemplatePopupComponent } from "./select-template-popup/select-template-popup.component";
import { CreateNewTemplateComponent } from "./create-new-template/create-new-template.component";
import { SaveTemplateNamePopupComponent } from "./save-template-name-popup/save-template-name-popup.component";
import { CSharpReviewDetailsPopupComponent } from "./csharp-review-details-popup/csharp-review-details-popup.component";
import { AngularReviewDetailsPopupComponent } from "./angular-review-details-popup/angular-review-details-popup.component";
import { UIReviewDetailsPopupComponent } from "./uireview-details-popup/uireview-details-popup.component";
import { QAReviewDetailsPopupComponent } from "./qareview-details-popup/qareview-details-popup.component";
import { ByStatusComponent } from "./by-status/by-status.component";
import { ByCustomerComponent } from "./by-customer/by-customer.component";
import { ByImplementerComponent } from "./by-implementer/by-implementer.component";
import { ByRepsonsiblePersonComponent } from "./by-repsonsible-person/by-repsonsible-person.component";
import { ByMajorTopicsComponent } from "./by-major-topics/by-major-topics.component";
import { BySubTopicsComponent } from "./by-sub-topics/by-sub-topics.component";
import { MyAppointmentsComponent } from "./my-appointments/my-appointments.component";
import { AddEditAppointmentsPopupComponent } from "./add-edit-appointments-popup/add-edit-appointments-popup.component";
import { CustomerTicketsComponent } from "./customer-tickets/customer-tickets.component";
import { PracticeStatusCheckListComponent } from "./practice-status-check-list/practice-status-check-list.component";
import { CheckListItemComponent } from "./check-list-item/check-list-item.component";
import { PracticeStatusCheckListItemsPopupComponent } from "./practice-status-check-list-items-popup/practice-status-check-list-items-popup.component";
import { AddPracticeCheckListItemPopupComponent } from "./add-practice-check-list-item-popup/add-practice-check-list-item-popup.component";
import { PracticeStatusCheckListItemHistoryPopupComponent } from "./practice-status-check-list-item-history-popup/practice-status-check-list-item-history-popup.component";
import { AddPracticeCheckListItemCommentsPopupComponent } from "./add-practice-check-list-item-comments-popup/add-practice-check-list-item-comments-popup.component";
import { AddPracticeCheckListItemStatusPopupComponent } from "./add-practice-check-list-item-status-popup/add-practice-check-list-item-status-popup.component";
import { EasyFormTaskReportComponent } from "./easy-form-task-report/easy-form-task-report.component";
import { AllCustomerTicketsComponent } from "./all-customer-tickets/all-customer-tickets.component";
import { AllAppointmentsComponent } from "./all-appointments/all-appointments.component";
import { CheckListItemPopupComponent } from "./check-list-item-popup/check-list-item-popup.component";
import { TicketInformationComponent } from "./ticket-information/ticket-information.component";
import { UserSelectionLoginPageComponent } from "./user-selection-login-page/user-selection-login-page.component";
import { SupportEmployeeLoginPageComponent } from "./support-employee-login-page/support-employee-login-page.component";
import { CustomerAddTicketPopupComponent } from "./customer-add-ticket-popup/customer-add-ticket-popup.component";
import { CustomerCallLogComponent } from "./customer-call-log/customer-call-log.component";
import { AddEditCallLogDetailsPopupComponent } from "./add-edit-call-log-details-popup/add-edit-call-log-details-popup.component";
import { AddEditCallLogDetailsPopupService } from "./add-edit-call-log-details-popup/add-edit-call-log-details-popup.service";
import { CustomerCallLogService } from "./customer-call-log/customer-call-log.service";
import { OverDueReportComponent } from "./over-due-report/over-due-report.component";
import { OverDueReportService } from "./over-due-report/over-due-report.service";
import { ViewAppointmentsPopupService } from "./view-appointments-popup/view-appointments-popup.service";
import { CustomerCallLogViewCommentsPopupService } from "./customer-call-log-view-comments-popup/customer-call-log-view-comments-popup.service";
import { ScheduledMeetingChangeStatusPopupComponent } from "./scheduled-meeting-change-status-popup/scheduled-meeting-change-status-popup.component";
import { ScheduledMeetingAddCommentsPopupComponent } from "./scheduled-meeting-add-comments-popup/scheduled-meeting-add-comments-popup.component";
import { ViewAppointmentsPopupComponent } from "./view-appointments-popup/view-appointments-popup.component";
import { CustomerCallLogViewCommentsPopupComponent } from "./customer-call-log-view-comments-popup/customer-call-log-view-comments-popup.component";
import { NewCustomerMailsSelectTemplatePopupComponent } from "./new-customer-mails-select-template-popup/new-customer-mails-select-template-popup.component";
import { NewCustomerMailsAddEditTemplatePopupComponent } from "./new-customer-mails-add-edit-template-popup/new-customer-mails-add-edit-template-popup.component";
import { ViewIconCellRendererComponent } from "./view-icon-cell-renderer/view-icon-cell-renderer.component";
import { NewCustomerMailsSelectTemplatePopupService } from "./new-customer-mails-select-template-popup/new-customer-mails-select-template-popup.service";
import { NewCustomerMailsAddEditTemplatePopupService } from "./new-customer-mails-add-edit-template-popup/new-customer-mails-add-edit-template-popup.service";
import { EdituncustomizedtasklistService } from "./edituncustomizedtasklist/edituncustomizedtasklist.service";
import { CreateTaskService } from "./shared/services/create-ticket.service";
import { ExcelService } from "./common/excel.service";
import { ViewTaskInformationService } from "./view-task-information/view-task-information.service";
import { AllCustomerTicketsService } from "./all-customer-tickets/all-customer-tickets.service";
import { CustomerTicketsService } from "./customer-tickets/customer-tickets.service";
import { EasyFormTaskReportService } from "./easy-form-task-report/easy-form-task-report.service";
import { ByMajorTopicsService } from "./by-major-topics/by-major-topics.service";
import { AllAppointmentsService } from "./all-appointments/all-appointments.service";
import { ResponsiblePersonPopupComponent } from "src/responsible-person-popup/responsible-person-popup.component";
import { EcdAndRbdSelectionPopupComponent } from "src/ecd-and-rbd-selection-popup/ecd-and-rbd-selection-popup.component";
import { ViewFullTaskInformationService } from "./view-full-task-information/view-full-task-information.service";
import { MoveIconCellRendererComponent } from "./move-icon-cell-renderer/move-icon-cell-renderer.component";
import { EmployeesCustomizationComponent } from "./employees-customization/employees-customization.component";
import { EmployeesListService } from "./shared/services/employees-list.service";
import { CloneService } from "./shared/services/clone.service";
import { FocusOnDefaultDirectiveModule } from "./shared/directives/focus-on-default.directive";
import { SelectRoleToAddPopupComponent } from "./select-role-to-add-popup/select-role-to-add-popup.component";
import { PracticeUsersCustomizationComponent } from "./practice-users-customization/practice-users-customization.component";
import { PracticeUsersCustomizationService } from "./practice-users-customization/practice-users-customization.service";
import { PracticeUserCcPersonPopupService } from './practice-user-cc-person-popup/practice-user-cc-person-popup.service'
import { CreateNewCustomerTaskComponent } from "./create-new-customer-task/create-new-customer-task.component";
import { AdditionalAndObserversForPracticePopupComponent } from "./additional-and-observers-for-practice-popup/additional-and-observers-for-practice-popup.component";
import { PracticeUserCcPersonPopupComponent } from './practice-user-cc-person-popup/practice-user-cc-person-popup.component';
import { StatisticsReportComponent } from './statistics-report/statistics-report.component';
import { StatisticsReportService } from "./statistics-report/statistics-report.service";
import { TaskBucketDetailsService } from "./shared/services/task-bucket-details";
import { NotRespondedTicketsReportListComponent } from './not-responded-tickets-report-list/not-responded-tickets-report-list.component';
import { NotRespondedTicketsReportListService } from "./not-responded-tickets-report-list/not-responded-tickets-report-list.service";
import { AllBucketsConsolidateReportComponent } from './all-buckets-consolidate-report/all-buckets-consolidate-report.component';
import { AllBucketsConsolidateReportService } from "./all-buckets-consolidate-report/all-buckets-consolidate-report.service";
import { ViewConsolidatedTaskdetailsComponent } from './view-consolidated-taskdetails/view-consolidated-taskdetails.component';
import { PracticeInformationComponent } from './practice-information/practice-information.component';
import { SupportStatisticsMainViewComponent } from './support-statistics-main-view/support-statistics-main-view.component';
import { SupportStatisticsViewComponent } from './support-statistics-view/support-statistics-view.component';
import { TicketMonitoringReportComponent } from './ticket-monitoring-report/ticket-monitoring-report.component';
import { CustomerTicletsCountByPracticeWiseComponent } from './customer-ticlets-count-by-practice-wise/customer-ticlets-count-by-practice-wise.component';
import { AddTaskTypePopupComponent } from './add-task-type-popup/add-task-type-popup.component';
import { CreateNewCustomerTicketPopUpComponent } from './create-new-customer-ticket-pop-up/create-new-customer-ticket-pop-up.component';
import { ViewDraftCommentsComponent } from './view-draft-comments/view-draft-comments.component';


// import Quill from 'quill';
// import { ImageDrop } from 'quill-image-drop-module';

// Quill.register('modules/imageDrop', ImageDrop);
// import 'bootstrap/dist/js/bootstrap.min.js';
// import 'summernote/dist/summernote.min.js';

// import { SummernoteComponent } from 'ng2-alt-summernote';
// import { RichTextEditorAllModule } from '@syncfusion/ej2-angular-richtexteditor';

// import { CreateTaskInformationPdfPopupComponent } from './create-task-information-pdf-popup/create-task-information-pdf-popup.component';
// import { NotAssignedEmailsToAnyoneComponent } from './not-assigned-emails-to-anyone/not-assigned-emails-to-anyone.component';

// import { SafePipe } from './common/commonhelper';

// import { SafePipe } from './common/commonhelper';

@NgModule({
  // declarations are to make directives (including components and pipes)
  // from the current module available to other directives in the current module.
  // Selectors of directives, components or pipes are only matched against the HTML if they are declared or imported.
  declarations: [
    AppComponent,
    OasLoginComponentComponent,
    OasSignupComponentComponent,
    OasMainDashboardComponentComponent,
    EmployeeInfoComponentComponent,
    AddTaskInformationComponent,
    ViewTaskInformationComponent,
    ViewFullTaskInformationComponent,
    EmployeeDashboardViewComponent,
    ProjectHierarchyInfoComponent,
    CreateNewTaskComponentComponent,
    ViewFullImageComponentComponent,
    CommonMessageInfoComponent,
    AddProjectsInformationComponent,
    EditTaskInformationComponent,
    TaskCustomizationInformationComponent,
    AddTaskCustomizationInformationComponent,
    PraticesListComponent,
    AddTaskCustomizationBasedOnEmailAddressComponent,
    EditTaskInformationBasedOnEmailAddressComponent,
    EditTaskCustomizationBasedOnUsersComponent,
    PracticeUsersListComponent,
    UncustomizedTaskListComponent,
    EdituncustomizedtasklistComponent,
    IntegerDirective,
    ViewTaskInformationComponentMainComponent,
    EnterMultipleEmailAddressPopupComponent,
    ViewDraftCommentsComponent,
    // NotAssignedEmailsToAnyoneComponent,
    Safe,
    TrimWhitespacesDirective,
    PdfAttachmentsDisplayComponent,
    ResponsiblepersonUsersListComponent,
    UncustomizedtaskCommentsComponent,
    TaskCommentAttachmentsCellrendererComponent,
    TaskCommentContentCellrendererComponent,
    SelectModulePopupComponentComponent,
    ChangePasswordPopupComponentComponent,
    AdditionalParticipantAndObserversPopupComponentComponent,
    PracticeusersinformationcomponentComponent,
    DeleteSelectedPersonRendererComponent,
    TestingComponent,
    AllTasksSummaryByForwardedToMeBucketComponent,
    ReportByResponsibleEmployeeComponent,
    AccessInformationComponent,
    ViewAccessInformationPopupComponent,
    AddEmployeeInfoPopupComponent,
    ContactComponent,
    EditAccessDetailsPopupComponent,
    MonthSummaryReportComponent,
    SelectTemplatePopupComponent,
    CreateNewTemplateComponent,
    SaveTemplateNamePopupComponent,
    CSharpReviewDetailsPopupComponent,
    AngularReviewDetailsPopupComponent,
    UIReviewDetailsPopupComponent,
    QAReviewDetailsPopupComponent,
    ByStatusComponent,
    ByCustomerComponent,
    ByImplementerComponent,
    ByRepsonsiblePersonComponent,
    ByMajorTopicsComponent,
    BySubTopicsComponent,
    MyAppointmentsComponent,
    AddEditAppointmentsPopupComponent,
    CustomerTicketsComponent,
    PracticeStatusCheckListComponent,
    CheckListItemComponent,
    PracticeStatusCheckListItemsPopupComponent,
    AddPracticeCheckListItemPopupComponent,
    PracticeStatusCheckListItemHistoryPopupComponent,
    AddPracticeCheckListItemCommentsPopupComponent,
    AddPracticeCheckListItemStatusPopupComponent,
    EasyFormTaskReportComponent,
    AllCustomerTicketsComponent,
    AllAppointmentsComponent,
    CheckListItemPopupComponent,
    TicketInformationComponent,
    UserSelectionLoginPageComponent,
    SupportEmployeeLoginPageComponent,
    CustomerAddTicketPopupComponent,
    CustomerCallLogComponent,
    AddEditCallLogDetailsPopupComponent,
    OverDueReportComponent,
    ScheduledMeetingChangeStatusPopupComponent,
    ScheduledMeetingAddCommentsPopupComponent,
    ViewAppointmentsPopupComponent,
    CustomerCallLogViewCommentsPopupComponent,
    NewCustomerMailsSelectTemplatePopupComponent,
    NewCustomerMailsAddEditTemplatePopupComponent,
    ViewIconCellRendererComponent,
    ResponsiblePersonPopupComponent,
    EcdAndRbdSelectionPopupComponent,
    MoveIconCellRendererComponent,
    EmployeesCustomizationComponent,
    SelectRoleToAddPopupComponent,
    PracticeUsersCustomizationComponent,
    CreateNewCustomerTaskComponent,
    AdditionalAndObserversForPracticePopupComponent,
    PracticeUserCcPersonPopupComponent,
    StatisticsReportComponent,
    NotRespondedTicketsReportListComponent,
    AllBucketsConsolidateReportComponent,
    ViewConsolidatedTaskdetailsComponent,
    PracticeInformationComponent,
    SupportStatisticsMainViewComponent,
    SupportStatisticsViewComponent,
    TicketMonitoringReportComponent,
    CustomerTicletsCountByPracticeWiseComponent,
    AddTaskTypePopupComponent,
    CreateNewCustomerTicketPopUpComponent,
    ViewDraftCommentsComponent,

    // CreateTaskInformationPdfPopupComponent,
  ],
  // imports makes the exported declarations of other modules available in the current module
  imports: [
    BrowserModule,
    AppRoutingModule,
    InputsModule,
    MatDatepickerModule,
    MatInputModule,
    MatNativeDateModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    ButtonsModule,
    HttpClientModule,
    AgGridModule.withComponents([
      TaskCommentAttachmentsCellrendererComponent,
      TaskCommentContentCellrendererComponent,
      DeleteSelectedPersonRendererComponent,
      ViewIconCellRendererComponent,
      MoveIconCellRendererComponent,
    ]),
    DropDownsModule,
    BrowserAnimationsModule,
    UploadModule,
    // NgxEditorModule,
    FormsModule,
    ReactiveFormsModule,
    AngularFontAwesomeModule,
    // SafePipeModule,
    NotificationModule,
    // FroalaEditorModule.forRoot(),
    // FroalaViewModule.forRoot(),
    NgxMaskModule.forRoot(),
    DatePickerModule,
    NgBusyModule,
    DialogsModule,
    LayoutModule,
    // NgxEditorModule,
    QuillModule,
    //Quill,
    //base64StringToBlob,
    // QuillImageDropAndPaste,
    ContextMenuModule,
    FocusOnDefaultDirectiveModule,
    MenuModule,
    DateInputsModule,
    TextMaskModule,
    MatListModule
    // SummernoteComponent
    // RichTextEditorAllModule
    // SafePipe
  ],
  // providers are to make services and values known to DI.
  // They are added to the root scope and they are injected to other services or directives that have them as dependency.
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UploadInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptor,
      multi: true,
    },
    commonHelperService,
    AuthenticationService,
    TaskService,
    LeftNavService,
    DialogService,
    commonhelper,
    CustomerCallLogService,
    AddEditCallLogDetailsPopupService,
    OverDueReportService,
    ViewAppointmentsPopupService,
    CustomerCallLogViewCommentsPopupService,
    NewCustomerMailsSelectTemplatePopupService,
    NewCustomerMailsAddEditTemplatePopupService,
    EdituncustomizedtasklistService,
    CreateTaskService,
    ExcelService,
    ViewTaskInformationService,
    AllCustomerTicketsService,
    StatisticsReportService,
    CustomerTicketsService,
    EasyFormTaskReportService,
    ByMajorTopicsService,
    AllAppointmentsService,
    ViewFullTaskInformationService,
    EmployeesListService,
    CloneService,
    PracticeUsersCustomizationService,
    PracticeUserCcPersonPopupService,
    TaskBucketDetailsService,
    NotRespondedTicketsReportListService,
    AllBucketsConsolidateReportService
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
  entryComponents: [
    AddTaskCustomizationInformationComponent,
    PraticesListComponent,
    AddTaskCustomizationInformationComponent,
    EditTaskInformationBasedOnEmailAddressComponent,
    EditTaskCustomizationBasedOnUsersComponent,
    PracticeUsersListComponent,
    EnterMultipleEmailAddressPopupComponent,
    ResponsiblepersonUsersListComponent,
    UncustomizedtaskCommentsComponent,
    SelectModulePopupComponentComponent,
    ChangePasswordPopupComponentComponent,
    AdditionalParticipantAndObserversPopupComponentComponent,
    PracticeusersinformationcomponentComponent,
    CreateNewTaskComponentComponent,
    // CreateTaskInformationPdfPopupComponent
    AllTasksSummaryByForwardedToMeBucketComponent,
    ReportByResponsibleEmployeeComponent,
    AccessInformationComponent,
    ViewAccessInformationPopupComponent,
    AddEmployeeInfoPopupComponent,
    EditAccessDetailsPopupComponent,
    MonthSummaryReportComponent,
    SelectTemplatePopupComponent,
    CreateNewTemplateComponent,
    SaveTemplateNamePopupComponent,
    CSharpReviewDetailsPopupComponent,
    AngularReviewDetailsPopupComponent,
    UIReviewDetailsPopupComponent,
    QAReviewDetailsPopupComponent,
    AddEditAppointmentsPopupComponent,
    CheckListItemComponent,
    PracticeStatusCheckListItemsPopupComponent,
    AddPracticeCheckListItemPopupComponent,
    PracticeStatusCheckListItemHistoryPopupComponent,
    AddPracticeCheckListItemCommentsPopupComponent,
    AddPracticeCheckListItemStatusPopupComponent,
    CheckListItemPopupComponent,
    CustomerAddTicketPopupComponent,
    AddEditCallLogDetailsPopupComponent,
    ScheduledMeetingChangeStatusPopupComponent,
    ScheduledMeetingAddCommentsPopupComponent,
    ViewAppointmentsPopupComponent,
    CustomerCallLogViewCommentsPopupComponent,
    NewCustomerMailsSelectTemplatePopupComponent,
    NewCustomerMailsAddEditTemplatePopupComponent,
    ResponsiblePersonPopupComponent,
    EcdAndRbdSelectionPopupComponent,
    EmployeesCustomizationComponent,
    SelectRoleToAddPopupComponent,
    PracticeUsersCustomizationComponent,
    CreateNewCustomerTaskComponent,
    AdditionalAndObserversForPracticePopupComponent,
    PracticeUserCcPersonPopupComponent,
    AllBucketsConsolidateReportComponent,
    ViewConsolidatedTaskdetailsComponent,
    SupportStatisticsMainViewComponent,
    SupportStatisticsViewComponent,
    TicketMonitoringReportComponent,
    CustomerTicletsCountByPracticeWiseComponent,
    AddTaskTypePopupComponent,
    CreateNewCustomerTicketPopUpComponent,
    ViewDraftCommentsComponent
  ],
})
export class AppModule {}
