import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { commonhelper } from '../common/commonhelper';
import { TaskService } from '../services/task.service';
import { DialogService, DialogRef } from '@progress/kendo-angular-dialog';
import { commonHelperService } from '../common/common.service';
import { AgGridNg2 } from 'ag-grid-angular';
import { GridOptions } from 'ag-grid-community';
import { Observable } from 'rxjs';
import { Config } from '../config';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { IpopuModel } from '../practice-users-customization/practice-users-customization.interface';

@Component({
  selector: 'app-responsibleperson-users-list',
  templateUrl: './responsibleperson-users-list.component.html',
  styleUrls: ['./responsibleperson-users-list.component.css']
})
export class ResponsiblepersonUsersListComponent extends commonhelper implements OnInit {

  enableSingleOrMultiple: string;
  private commonhelperobj: commonHelperService;
  @ViewChild('responsiblePersonGrid') responsiblePersonGrid: AgGridNg2;
  @ViewChild('responsiblePersonGridMultiSelection') responsiblePersonGridMultiSelection: AgGridNg2;
  public responsiblePersonGridOptions: GridOptions;
  public gridApi;
  public gridColumnApi;
  public selectedRowsString: {};
  public SearchEmployeeName: any = '';
  public SelectedResponsiblePersonName: any = '';
  public IsFromForwardToPopup: any = false;
  public StateMaintainUsersData: any;
  public rowSelection;
  public columnDefs;
  public singleselectioncolumnDefs;
  public defaultColDef;
  rowData: any = [];
  datatoPopup: any;
  public gridOptions: GridOptions;

  constructor(private httpClient: HttpClient,
    private taskService: TaskService,
    private dialogService: DialogService,
    private dialog: DialogRef,
    private http: HttpClient) {
    super();
    this.commonhelperobj = new commonHelperService(httpClient);
    this.gridOptions = <GridOptions>{
      context: {
        componentParent: this
      },
      defaultColDef: {
        // resizable: true
      }
    };

    this.singleselectioncolumnDefs = [
      //
      {
        headerName: 'Employee ID', field: 'EmployeeId', width: 0, cellClass: 'no-border cell-wrap-text',
        autoHeight: true, hide: true
      },
      {
        headerName: 'Person', field: 'EmployeeFullName', width: 490, cellClass: 'no-border cell-wrap-text',
        autoHeight: true,
      },
    ];

    this.columnDefs = [
      //
      {
        headerName: 'Employee ID', field: 'EmployeeId', width: 0, cellClass: 'no-border cell-wrap-text',
        autoHeight: true, hide: true
      },
      {
        headerName: 'Person', field: 'EmployeeFullName', width: 490, cellClass: 'no-border cell-wrap-text', headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
        autoHeight: true,
      },
    ];
    this.defaultColDef = {
      resizable: true,
      width: 100
    };


    this.rowSelection = "multiple";
  }

  @ViewChild('UncustomizedTaskComment') UncustomizedTaskComment: ElementRef;

  ngOnInit() {

    if(this.datatoPopup!==undefined){
    // console.log(this.datatoPopup,this.datatoPopup.FromInternalTickets)
    this.IsFromForwardToPopup= this.datatoPopup.FromInternalTickets;
    this.rowSelection=this.datatoPopup.IsSelectionType;
    }
    this.employeeList();
    //debugger;
    setTimeout(function () {
      document.getElementById("divSearchUser").focus();
    }, 10);


  }

  onModelUpdated() {
    if (this.IsFromForwardToPopup == true) {
      if (this.hasValue(this.StateMaintainUsersData)) {
        this.StateMaintainUsersData.forEach((selectedRow, index) => {

          this.responsiblePersonGridMultiSelection.api.forEachNode((node) => {

            if (node.data.EmployeeId === parseInt(selectedRow.EmployeeId)) {
              //alert("3");
              // Master open detail.  You could also call node.setSelected( true ); for alternate design.
              node.setSelected(true);
            }
          });
        });
      }
    }
    // else {
    //   if (this.hasValue(this.StateMaintainUsersData)) {
    //     this.StateMaintainUsersData.forEach((selectedRow, index) => {

    //       this.responsiblePersonGrid.api.forEachNode((node) => {

    //         if (node.data.EmployeeId === parseInt(selectedRow.EmployeeId)) {
    //           //alert("3");
    //           // Master open detail.  You could also call node.setSelected( true ); for alternate design.
    //           node.setSelected(true);
    //         }
    //       });
    //     });
    //   }
    // }
  }

  employeeList() {
    const PostDataToService = {
      EmployeeFullName: this.SearchEmployeeName,
    };
    return this.commonhelperobj.PostData(Config.OASOrganizationAppnURL + 'OrganizationEmployeesList', PostDataToService)
      .subscribe(response => {
        if (this.isError(response)) {
          return;
        }
        this.rowData = response.EmployeeList;

      });

  }

  onSelectionChanged(event) {
    const selectedRows = this.responsiblePersonGrid.api.getSelectedRows();

    selectedRows.forEach((selectedRow, index) => {
      //debugger;
      if (index !== 0) {
        this.selectedRowsString = {};
      }
      this.selectedRowsString = selectedRow;
      this.SelectedResponsiblePersonName = selectedRow.EmployeeFullName;

    });
    // console.log(this.selectedRowsString);
  }

  onSelectionChangedforMultipleSelection(event) {
    const selectedRows = this.responsiblePersonGridMultiSelection.api.getSelectedRows();

    selectedRows.forEach((selectedRow, index) => {
      //debugger;
      if (index !== 0) {
        this.selectedRowsString = {};
      }
      this.selectedRowsString = selectedRow;
      this.SelectedResponsiblePersonName = selectedRow.EmployeeFullName;

    });
    // console.log(this.selectedRowsString);
  }

  OKClickEvent() {
    if (this.IsFromForwardToPopup == false) {
      if (!this.hasValue(this.selectedRowsString)) {
        this.ShowErrorMessage('Please Select User(s).');
        return false;
      }
      if (this.hasValue(this.selectedRowsString)) {
        setTimeout(() => {
          this.dialog.close(this.selectedRowsString);
        });
      }
    }
    else {
      const selectedRows = this.responsiblePersonGridMultiSelection.api.getSelectedRows();
      if (!this.hasValue(selectedRows)) {
        this.ShowErrorMessage('Please Select User(s).');
        return false;
      }
      if (this.hasValue(selectedRows)) {
        setTimeout(() => {
          this.dialog.close(selectedRows);
        });
      }

      // if (!this.hasValue(this.selectedRowsString)) {
      //   this.ShowErrorMessage('Please Select Responsible Person.');
      //   return false;
      // }
      // if (this.hasValue(this.selectedRowsString)) {
      //   setTimeout(() => {
      //     this.dialog.close(this.selectedRowsString);
      //   });
      // }

    }
  }
  CancelClickEvent() {
    this.dialog.close();
  }

  onSearchChanged() {
    if (this.IsFromForwardToPopup == true) {
      this.responsiblePersonGridMultiSelection.api.setQuickFilter(this.SearchEmployeeName);
    }
    else {
      this.responsiblePersonGrid.api.setQuickFilter(this.SearchEmployeeName);
    }
  }



}
