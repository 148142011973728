import { Component, OnInit, ElementRef, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { commonHelperService } from '../common/common.service';
import { commonhelper } from '../common/commonhelper';
import { Config } from '../config';

import * as $ from 'jquery';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { SelectEvent, CancelEvent, RemoveEvent } from '@progress/kendo-angular-upload';
import { TaskChangeEvent } from '../interfaces/TaskChangeEvent';
import { TaskEventTypeEnum } from '../enums/TaskHistoryChangeEvent.enum.';
import { EmployeeService } from '../employee.service';
import { FileUploadProgressService } from '../services/file-upload-progress.service';
import { UploadState } from '../enums/upload-state.enum';
import { AttachmentService } from '../services/attachment.service';
import { Attachment } from '../interfaces/attachment';
import { DialogService, DialogCloseResult } from '@progress/kendo-angular-dialog';
import { ResponsiblepersonUsersListComponent } from '../responsibleperson-users-list/responsibleperson-users-list.component';
import { QuillModule } from 'ngx-quill';
import { PraticesListComponent } from '../pratices-list/pratices-list.component';
import { SelectModulePopupComponentComponent } from '../select-module-popup-component/select-module-popup-component.component';
import { IntlService } from '@progress/kendo-angular-intl';
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';
import { AdditionalParticipantAndObserversPopupComponentComponent } from '../additional-participant-and-observers-popup-component/additional-participant-and-observers-popup-component.component';
import { forEach } from '@angular/router/src/utils/collection';
import { CreateTaskService } from '../shared/services/create-ticket.service';
import { isEqual, differenceWith } from 'lodash'
import * as _ from 'lodash';
//import QuillImageDropAndPaste from 'quill-image-drop-and-paste'


@Component({
  selector: 'app-edit-task-information',
  templateUrl: './edit-task-information.component.html',
  styleUrls: ['./edit-task-information.component.css'],
  providers: [
    FileUploadProgressService
  ]
})
export class EditTaskInformationComponent extends commonhelper implements OnInit {



  selectedTaskCurrentWorkingStatus: any = {};
  initialTaskCurrentWorkingStatus: any;
  PopupDataFromMultiSelectUserSelectionPopup: any;
  IsAssistantPersonInfoChanged: boolean = false;
  PreviousSelectedAssistantPerson: Employee[];
  SelectedAssistantPerson: any = [];
  SelectedAssistantPersonInfo: any;
  SelectedAssistantPersonName: string = "";
  IsresponsiblePersonAndFwdToSame: boolean = false;
  PreviousselectedParticipants: Employee[];
  TaskUserInfoinEditMode: any;
  PreviousselectedObservers: Employee[];
  IsAdditionalResponsiblePersonInfoChanged: boolean = false;
  SelectedObserversPersonNames: string;
  IsObserversInfoChanged: boolean = false;
  SelectedObserversInfo: any;
  SelectedAdditionalResponsiblePersonName: string;
  SelectedAdditionalResponsiblePersonInfo: any;
  SelectedTaskTypeInfo: any;
  ViewAttachmentHeaderInfo: any;
  FirstTimeLoad: boolean;
  TaskNumber = 0;
  @ViewChild('EditTaskInfo') EditTaskInfo: ElementRef;
  //@ViewChild('EditTaskInfo') containerRef: ElementRef;
  @ViewChild("EditTaskInfo", { read: ViewContainerRef })
  public ContainerRef: ViewContainerRef;

  // intially selected participants array for this task
  private initialParticipants: Array<any> = [];

  // intially selected observers array for this selected task
  private initialObservers: Array<any> = [];

  // initially selected responsible person for this task
  private initialResponsiblePerson: any = {};

  private initialQAPerson: any = {};
  private initialCSharpPerson: any = {};
  private initialDBPerson: any = {};
  private initialJSPerson: any = {};

  private previousResponsibleperson: any = {};

  private previousQAperson: any = {};
  private previousCSharpperson: any = {};
  private previousDBperson: any = {};
  private previousJSperson: any = {};

  // intiially selected project
  public initialProject: any = {} as any;

  // intially selected module
  public initialModule: any = {};

  // intially selected severity
  public initialSeverity: any = {};

  stateMaintainresponsiblePerson = "";
  // initially selected deadline date if exists
  public initialDeadlineDate: Date;

  // intially selected task body / description
  public initialTaskBody = '';

  // initially selected task type
  public intialTaskType = {} as any;

  // mainly used to post data to backend
  private commonhelperobj: commonHelperService;

  // array used to hold projects to show in projects dropdown
  public projectList: Array<any> = [];

  // array used to hold modules to show in modules dropdown
  public moduleList: Array<any> = [];

  // types of severities to show in severity dropdown
  public severityList: Array<any> = [];

  // TODO: Remove this
  public EmployeesAndRolesModelList: Array<any> = [];

  // this objects holds the employees selected as Additional Responsible Persons while creating the view
  public selectedAdditionalResponsiblePersons: any = [];

  // this objects holds the employees selected as observers while creating the view
  public selectedObservers: any = [];

  // this object holds the currently selected task type
  public selectedTaskType = {} as any;

  // selected project in task creation
  public selectedProject = {} as any;

  // selected mdoule in task creation
  public selectedModule = {} as any;

  // severity selected in task creation
  public selectedSeverity = {} as any;

  // responsible person selected in task creation
  public responsiblePerson: Employee;

  // assistant person to forward the task
  public assistantPerson: any = {};

  public SelectedPracticeInfo: any = {};

  public SelectedQAPersonInfo: any = {};

  public SelectedCSharpPersonInfo: any = {};

  public SelectedDBPersonInfo: any = {};

  public SelectedJSPersonInfo: any = {};
  //
  public dialogOpened = false;

  public taskTypeList = [];

  //public EditInfo:any;

  public uploadedFiles = {} as any;

  ModuleListInfo: any;

  previouslySelectedEmployees: Array<Employee> = []
  public taskEditInfoModel = {
    IssueSeverityID: '',    // TODO: remove this property
    RequiredByDate: '',
    IssueSubject: '',
    severity: '',           // TODO: remove this property
    SelectedTaskBody: '',
    id: '',                 // TODO: remove this property
  } as any;

  fullattachmentsList: any;

  // TODO: remove this class object
  ifCommentsCountisLessThanOne: boolean;

  // TODO: remove this class object
  taskBodyList: any;

  // TODO: remove this class object
  MainBodyAttachmentsList: string;

  // TODO: remove this class object
  MainBodyImagesAttachmentsList: any[];

  // TODO: remove this class object
  imagesattachmentslist: any[];

  AttachmentsList: any[];

  // initialAttachments: Array<any>;

  hideAttachments = true;

  // TODO: remove this class object
  srcextractedlist: any;

  uploadSaveUrl = 'saveUrl'; // should represent an actual API endpoint

  uploadRemoveUrl = 'removeUrl'; // should represent an actual API endpoint

  // list of organization employees
  // this list is used to select additional participants, observers
  organizationEmployees: any;

  // AttachmentsModel: { AttachmentBase64Format: string; AttachmentType: number; };

  // AttachmentsModelList: any[] = [];

  taskImageSRC: any;
  fileDeleted: boolean;
  fileUploaded: boolean;
  public selectTaskType: any;
  filesCount: any;
  pdfAttachments: Attachment[];
  EditNewCurrentWorkingStatusList: any;



  // editorCreated(quill) {
  // quill.register('modules/imageDropAndPaste', QuillImageDropAndPaste)
  // }


  // modules = {
  //  imageDropAndPaste: {
  // add an custom image handler
  //    handler: function () {
  //     console.log(arguments);
  //     debugger
  //   }
  // }
  // }

  constructor(private route: ActivatedRoute, private http: HttpClient,
    private router: Router, private employeeService: EmployeeService,
    private uploadStateService: FileUploadProgressService,
    private attachmentService: AttachmentService,
    private dialogService: DialogService,
    private intl: IntlService,
    private sanitizer: DomSanitizer,
    private readonly _createNewTaskService: CreateTaskService
  ) {
    super();
    this.commonhelperobj = new commonHelperService(http);
  }


  public defaultProjectItem = { Description: '-- Select --', ProjectId: null }

  public defaultModuleItem = { Description: '-- Select --', ModuleId: null, }

  public defaultSeverityItem = { IssueSeverityDesc: '-- Select --', IssueSeverityID: null }

  public defaultTaskTypeItem = { TaskTypeDescription: '-- Select --', TaskTypeId: null }

  public defaultResponsiblePersonTypeItem = { EmployeeFullName: '-- Select --', EmployeeId: null }

  public defaultEditTaskCurrentStatusItem = { Task_Current_Working_Status: '--Task Current Working Status --', Task_Current_Working_Status_InfoID: undefined }


  public defaultEditedCurrentTaskFromItem = { editSelectTaskFromListText: '- Select -', editSelectTaskFromListTextValue: 0 }

  public taskEditTaskSelectTaskFromList = [
    { editSelectTaskFromListText: 'Call', editSelectTaskFromListTextValue: 1 },
    { editSelectTaskFromListText: 'Email', editSelectTaskFromListTextValue: 2 },
    { editSelectTaskFromListText: 'Live Chat', editSelectTaskFromListTextValue: 3 },
    { editSelectTaskFromListText: 'Sales Chat', editSelectTaskFromListTextValue: 4 },
  ];

  public EditTaskCurrentStatusList = [
    { EditTaskCurrentStatusDesc: 'Pending', EditTaskCurrentStatusID: 1 },
    { EditTaskCurrentStatusDesc: 'Completed', EditTaskCurrentStatusID: 2 },
  ];



  ngOnInit() {
    /*** quill editor testing *** */
    /*var toolbarOptions = [
      ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
      ['blockquote', 'code-block'],

      [{ 'header': 1 }, { 'header': 2 }],               // custom button values
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
      [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
      [{ 'direction': 'rtl' }],                         // text direction

      //[{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
      [{ 'size': ['10px','small', 'huge'] }],  // custom dropdown
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

      [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
      [{ 'font': [] }],
      [{ 'align': [] }],

      ['clean']                                         // remove formatting button
    ];
*/





    //this.GetCurrentStatusList();

    this.route.params.subscribe(routeParams => {
      // get data passed from calling route
      const data = this.getCommonData(routeParams.info)['data'];
      //this.EditInfo=data;
      this.FirstTimeLoad = true;
      this.EditTaskInformation(data);
      // this.populate(data);
      //this.GetCurrentStatusList(data);

    });

    setTimeout(() => {
      const elementFound1 = this.EditTaskInfo.nativeElement.querySelector('[name="' + 'EditTaskProjectName' + '"]');
      if (this.hasValue(elementFound1)) {
        elementFound1.children[0].focus();
      }
    }, 100);
  }


  private EditTaskInformation(data) {
    var postData = {
      TaskInfoID: data.TaskInfoID
    }
    this.commonhelperobj.PostData(Config.OASOrganizationAppnURL + 'EmployeeTaskInformationGet', postData).subscribe(serviceResponse => {
      if (this.isError(serviceResponse)) { return; }
      if (this.hasValue(serviceResponse)) {
        this.TaskNumber = data.TaskNumber
        this.TaskInformationtostatemaintain(serviceResponse);

      }


      //this.initializeTaskCategoriesCount();
    });
  }

  /**
   * Popuate html page with data
   * @param data Data to populate
   * @author Sanjay Idpuganti
   */
  private TaskInformationtostatemaintain(datatostatemaintain) {
    // console.log(datatostatemaintain)
    // subject
    this.taskEditInfoModel.IssueSubject = datatostatemaintain.IssueSubject;

    this.taskEditInfoModel.CreatedDate = datatostatemaintain.IssueCreatedOn;
    this.taskEditInfoModel.TaskLatestStatusID = datatostatemaintain.TaskLatestStatusID;

    // deadline date
    this.taskEditInfoModel.RequiredByDate = this.initialDeadlineDate = this.parseDate(datatostatemaintain.RequiredByDate);

    this.SelectedPracticeInfo.PracticeName = datatostatemaintain.PracticeName;
    this.SelectedPracticeInfo.PracticeId = datatostatemaintain.EHRPracticeID;


    // auto populate severity dropdown
    this.taskEditInfoModel.severity = {
      IssueSeverityDesc: datatostatemaintain.SeverityDesc,
      IssueSeverityID: this.calculateSeverityId(datatostatemaintain.SeverityDesc)
    };

    // this list contains all the empooyees related to this particluar task
    // Responsible Person, Observer, Participant
    const taskRelatedEmployees = datatostatemaintain.TaskUsersInfo;



    this.TaskUserInfoinEditMode = datatostatemaintain.TaskUsersInfo

    // task id to send to database to do update operation
    this.taskEditInfoModel.id = datatostatemaintain.TaskInfoID;

    this.taskEditInfoModel.IsPublicTask = datatostatemaintain.IsPublicTask;

    const { responsiblePerson, taskObservers, taskParticipants, taskAssistant,taskQAPerson,taskCSharpPerson,taskDbPerson,taskJsPerson } = this.employeeService.parseEmployeeRoles(taskRelatedEmployees+",");
    //taskEditInfoModel.TaskCamefromType
    if (this.hasValue(datatostatemaintain.TaskCamefromType) && datatostatemaintain.TaskCamefromType > 0) {

      this.taskEditInfoModel.TaskCamefromType = { editSelectTaskFromListText: datatostatemaintain.TaskCamefromTypeDescription, editSelectTaskFromListTextValue: datatostatemaintain.TaskCamefromType };
    }

    // bind selected participants array to view and store initial data in an object
    // this.selectedAdditionalResponsiblePersons = this.initialParticipants = taskParticipants;

    if (this.hasValue(taskParticipants)) {
      this.selectedAdditionalResponsiblePersons = taskParticipants;
      this.SelectedAdditionalResponsiblePersonName = "";

      this.selectedAdditionalResponsiblePersons.forEach((selectedRow, index) => {
        // this.SelectedAssistantPerson.EmployeeId = selectedRow.EmployeeId;
        this.SelectedAdditionalResponsiblePersonName += selectedRow.EmployeeFullName + ', ';
      });

      this.SelectedAdditionalResponsiblePersonName = this.SelectedAdditionalResponsiblePersonName.substring(0, this.SelectedAdditionalResponsiblePersonName.length - 2);


    }

    // bind selected observers array to view and store initial data in an object
    // this.selectedObservers = this.initialObservers = taskObservers;

    if (this.hasValue(taskObservers)) {
      this.selectedObservers = taskObservers;
      this.SelectedObserversPersonNames = "";

      this.selectedObservers.forEach((selectedRow, index) => {
        // this.SelectedAssistantPerson.EmployeeId = selectedRow.EmployeeId;
        this.SelectedObserversPersonNames += selectedRow.EmployeeFullName + ', ';
      });

      this.SelectedObserversPersonNames = this.SelectedObserversPersonNames.substring(0, this.SelectedObserversPersonNames.length - 2);
    }

    // bind selected responsible pserson to view and store initial data in an object
    this.responsiblePerson = this.initialResponsiblePerson = responsiblePerson;
    if (this.hasValue(taskQAPerson)) {
    this.SelectedQAPersonInfo=this.initialQAPerson=taskQAPerson[0];
    }
    if (this.hasValue(taskCSharpPerson)) {
    this.SelectedCSharpPersonInfo=this.initialCSharpPerson=taskCSharpPerson[0];
    }
    if (this.hasValue(taskDbPerson)) {
    this.SelectedDBPersonInfo=this.initialDBPerson=taskDbPerson[0];
    }
    if (this.hasValue(taskJsPerson)) {
    this.SelectedJSPersonInfo=this.initialJSPerson=taskJsPerson[0];
    }
    this.stateMaintainresponsiblePerson = responsiblePerson.EmployeeId

    this.previousResponsibleperson = this.responsiblePerson;
    this.previousQAperson = this.SelectedQAPersonInfo;
    this.previousCSharpperson = this.SelectedCSharpPersonInfo;
    this.previousDBperson = this.SelectedDBPersonInfo;
    this.previousJSperson = this.SelectedJSPersonInfo;

    this.assistantPerson = taskAssistant;

    if (this.hasValue(taskAssistant)) {
      this.SelectedAssistantPerson = taskAssistant;
      this.SelectedAssistantPersonName = "";

      this.SelectedAssistantPerson.forEach((selectedRow, index) => {
        // this.SelectedAssistantPerson.EmployeeId = selectedRow.EmployeeId;
        this.SelectedAssistantPersonName += selectedRow.EmployeeFullName + ', ';
      });

      this.SelectedAssistantPersonName = this.SelectedAssistantPersonName.substring(0, this.SelectedAssistantPersonName.length - 2);


    }

    if (!this.hasValue(this.assistantPerson)) {
      this.assistantPerson = {};
      this.assistantPerson.EmployeeId = undefined;
      this.assistantPerson.EmployeeFullName = '';
    }

    // find selected project
    this.getProjects()
      .map(projects => this.findSelectedProject(projects, datatostatemaintain))
      .subscribe(taskProject => this.assignToSelectedProject(taskProject));

    // find selected module
    this.getModules()
      .map(modules => this.findSelectedModule(modules, datatostatemaintain))
      .subscribe(taskModule => this.assignToSelectedModule(taskModule));

    // find selected severity
    this.getSeverities()
      .map(severities => this.findSelectedSeverity(severities, datatostatemaintain))
      .subscribe(taskSeverity => this.assignToSelectedSeverity(taskSeverity));

    // find selected task type
    this.getTaskTypes()
      .map(taskTypes => this.findSelectedTaskType(taskTypes, datatostatemaintain.TaskTypeId))
      .subscribe(taskType => this.assignToSelectedTaskType(taskType));

    // find selected severity
    this.initialTaskCurrentWorkingStatus = datatostatemaintain.TaskCurrentWorkingStatusInfoID;

    // get organization employees
    // this.employeeList().subscribe(() => { });

    // populate task body as well as attachments
    this.GetTaskBodyList();
    this.GetCurrentStatusList(datatostatemaintain);
  }

  /**
   * Get organization projects from database
   * @author Sanjay Idpuganti
   */
  getProjects(): Observable<Array<any>> {
    const PostDataToService = {};
    return this.commonhelperobj.PostData(Config.OASProjectsAppnURL + 'GetProjectsList', PostDataToService)
      .pipe((map(response => this.checkForErrorAndAssignToProjectListAndReturn(response))));
  }

  /**
   * Get organization modules from database
   * @author Sanjay Idpuganti
   */
  getModules(): Observable<Array<any>> {
    const PostDataToService = {};
    return this.commonhelperobj.PostData( Config.OASAppnURL + "OASIssueSupportGroupsListView", PostDataToService)
      .pipe((map(response => this.checkForErrorAndAssignToModuleListAndReturn(response))));
  }

  /**
   * Get different types of severities from database
   * @author Sanjay Idpuganti
   */
  getSeverities(): Observable<Array<any>> {
    const PostDataToService = {};
    return this.commonhelperobj.PostData(Config.OASAppnURL + 'OASIssueSeverityListView', PostDataToService)
      .pipe((map(response => this.checkForErrorAndAssignToSeverityListAndReturn(response))));
  }

  getTaskTypes(): Observable<any> {
    const postData = {};
    return this.commonhelperobj.PostData(Config.OASAppnURL + 'TaskTypeList', postData)
      .pipe((map(response => this.checkForErrorAndAssignToTaskTypeList(response))));
  }



  /**
   * Get organization employees
   * @author Sanjay Idpuganti
   */
  // employeeList(): Observable<Array<any>> {
  //   const PostDataToService = {};
  //   return this.commonhelperobj.PostData(Config.OASOrganizationAppnURL + 'OrganizationEmployeesList', PostDataToService)
  //     .pipe((map(response => this.checkForErrorAndAssingToEmployeeListAndReturn(response))));
  // }

  public getSantizeUrl(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url)
  }

  updateDownloadHref(value) {
    var blob = value;
    var blobUrl = blob;
    var blobAsDataUrl = null;
    var xhr = new XMLHttpRequest;
    xhr.responseType = 'blob';

    xhr.onload = function () {
      var recoveredBlob = xhr.response;

      var reader = new FileReader;

      reader.onload = function () {
        blobAsDataUrl = reader.result;
        window.location = blobAsDataUrl;
      };

      reader.readAsDataURL(recoveredBlob);
    };

    xhr.open('GET', blobUrl);
    xhr.send();
    return blobAsDataUrl;
  }

  /**
   * Get editor string and attachements [Code taken from CreateTaskComponent]
   */
  GetTaskBodyList() {
    const PostDataToService = {
      TaskInfoID: this.taskEditInfoModel.id
    };
    this.commonhelperobj.PostData(Config.OASOrganizationAppnURL + 'EmployeeTaskAttachmentList1', PostDataToService)
      .subscribe(serviceResponse => {
        if (this.isError(serviceResponse)) { return; }
        this.fullattachmentsList = serviceResponse.AttachmentsModelList;

        let commentscount;
        if (this.fullattachmentsList.length > 0) {
          commentscount = this.fullattachmentsList[0].CommentsCount;
        }
        // tslint:disable-next-line:radix
        if (parseInt(commentscount) > 1) {
          this.ifCommentsCountisLessThanOne = true;
        }
        this.taskBodyList = this.fullattachmentsList.filter(item => item.AttachmentType === 1 && item.AttachmentUrlSequence === 0);


        this.commonhelperobj.GetStringBytesFromSignedURL(this.taskBodyList[0].AttachmentURL,
          'text/html').subscribe(successdata2 => {
            this.MainBodyAttachmentsList = '<div>' + successdata2 + '</div>';
            this.MainBodyImagesAttachmentsList = [];
            this.imagesattachmentslist = [];
            this.AttachmentsList = [];
            const GetMainBodyAttachmentsList = $(this.MainBodyAttachmentsList);
            const collectionofAttachments = GetMainBodyAttachmentsList.find('img');
            //GetMainBodyAttachmentsList.find('img').remove();
            let collectionofimgsLength2: any;
            collectionofimgsLength2 = collectionofAttachments.length;
            const elementfieldmainbody = {
              Imgaetag: null,
              attachmentType: null
            };
            const mainbodyinfo = GetMainBodyAttachmentsList.html();

            this.taskEditInfoModel.SelectedTaskBody = this.initialTaskBody = mainbodyinfo;

            // setTimeout(() => {
            //   for (let index2 = 0; index2 < collectionofimgsLength2; index2++) {
            //     // if (collectionofAttachments[index2].attributes['src'].value.includes("blob") == false) {
            //     elementfieldmainbody.Imgaetag = collectionofAttachments[index2].attributes['src'].value;
            //     //elementfieldmainbody.Imgaetag = this.updateDownloadHref(elementfieldmainbody.Imgaetag);
            //     // elementfieldmainbody.Imgaetag = elementfieldmainbody.Imgaetag.replace('SafeValue must use [property]=binding:', '')
            //     //   .replace('(see http://g.co/ng/security#xss)', '');
            //     // elementfieldmainbody.Imgaetag = this.getSantizeUrl(elementfieldmainbody.Imgaetag);
            //     // elementfieldmainbody.Imgaetag = elementfieldmainbody.Imgaetag.changingThisBreaksApplicationSecurity;
            //     elementfieldmainbody.attachmentType = 2;

            //     this.AttachmentsList.push(this.cloneObject(elementfieldmainbody));
            //     // }

            //     // const attachment = {
            //     //   AttachmentBase64Format: window.btoa(unescape(encodeURIComponent(elementfieldmainbody.Imgaetag.split('base64,')[1]))),
            //     //   AttachmentType: 2,
            //     // };

            //     // this.AttachmentsModelList.push(attachment);
            //     // this.AttachmentsModelList.push(this.cloneObject(elementfieldmainbody));
            //     this.hideAttachments = false;
            //   }
            // }, 10);

          });
        this.getAttachments();
        // this.url_content(this.taskBodyList[0].AttachmentURL).then(success => {
        //   this.TaskDescription = success;
        // });
      });
  }


  /**
   *  [Code taken from CreateTaskComponent]
   */
  getAttachments() {
    // assigning image attachments
    const elementfield = {
      Imgaetag: null,
      attachmentType: null,
      AttachmentName: "",
    };
    // tslint:disable-next-line:max-line-length
    this.imagesattachmentslist = this.fullattachmentsList.filter(item => (item.AttachmentType === 2 ||
      item.AttachmentType === 3 || item.AttachmentType === 4)
      && (item.AttachmentUrlSequence === 0));
    this.pdfAttachments = this.attachmentService.pdfAttachments(this.fullattachmentsList);
    if (this.imagesattachmentslist.length > 0) {
      setTimeout(() => {
        for (let index = 0; index < this.imagesattachmentslist.length; index++) {
          const SelectedAttachmentType = this.imagesattachmentslist[index].AttachmentType;
          // tslint:disable-next-line:max-line-length
          const urlsrcslist = this.imagesattachmentslist[index].AttachmentURL;
          let AttachmentName = '';
          if (this.hasValue(this.imagesattachmentslist[index].AttachmentName)) {
            AttachmentName = this.imagesattachmentslist[index].AttachmentName;
          }
          else {
            AttachmentName = '';
          }
          $.get(urlsrcslist).then(success => {
            this.srcextractedlist = success;
            elementfield.Imgaetag = 'data:image/png;base64' + ',' + this.srcextractedlist;
            elementfield.attachmentType = SelectedAttachmentType;
            elementfield.AttachmentName = AttachmentName;
            this.AttachmentsList.push(this.cloneObject(elementfield));

            this.hideAttachments = false;
          });

        }
      }, 200);
    }

    if (this.pdfAttachments && this.pdfAttachments.length > 0) {
      this.hideAttachments = false;
    }
  }

  open(component, imgeurl, imageInfo) {
    this[component + 'Opened'] = true;
    if (this.hasValue(imageInfo) && this.hasValue(imageInfo.AttachmentName)) {
      var AttachmentName = imageInfo.AttachmentName;
    }
    this.TasksLoadImageURL(imgeurl, AttachmentName);
  }

  TasksLoadImageURL(imageURL, AttachmentName) {
    if (this.hasValue(AttachmentName)) {
      this.ViewAttachmentHeaderInfo = AttachmentName
    }
    else {
      this.ViewAttachmentHeaderInfo = "View Attachment";
    }
    this.taskImageSRC = imageURL;
  }

  close(component) {
    this[component + 'Opened'] = false;
  }


  onPdfAttachmentDeleteClickEvent(pdfAttachmentIndex) {
    this.pdfAttachments.splice(pdfAttachmentIndex, 1);

    this.fileDeleted = true;
  }


  /**
   * Executes when user clicks close icon on attachment
   * @param attachmentIndex index of the attachment in attachment list to remove
   * @author Sanjay Idpuganti
   */
  onImageCloseIconClickEvent(attachmentIndex: number) {
    // validate attachmentIndex
    if (attachmentIndex > this.AttachmentsList.length - 1 && typeof attachmentIndex !== 'number') {
      throw new Error('Invalid attachment index');
    }

    // delete the selected index
    this.AttachmentsList.splice(attachmentIndex, 1);
    // this.AttachmentsModelList.splice(attachmentIndex, 1);

    this.fileDeleted = true;
  }


  // onRequiredByDateBlurEvent(newDate) {

  //   if (this.createdDateGreaterThanRequiredByDate()) {
  //     this.ShowErrorMessage('Expected Delivery Date Should be Greater than or Equal to Created Date');
  //     // const elementFound = this.EditTaskInfo.nativeElement.querySelector('[name="' + 'EditTaskExpectedDeliveryDatename' + '"]');
  //     // if (this.hasValue(elementFound)) {
  //     //   elementFound.children[0].children[0].children[0].children[0].focus();
  //     // }
  //     return;
  //   }

  // }

  onRequiredByDateBlurEvent(value: Date) {
    // let dateeee = this.formatValue(this.TaskAddInfoModel.ExpectedDate);

    // //return value ? ` - ${this.intl.formatDate(value, 'd')}` : '';
    // console.log(dateeee);
    // console.log(this.TaskAddInfoModel.ExpectedDate);

    if (!this.hasValue(this.taskEditInfoModel.RequiredByDate)) {
      this.taskEditInfoModel.RequiredByDate = '';
      this.EditTaskInfo.nativeElement.querySelector('#TaskAddNewExpectedDeliveryDate').value = '';
      //   this.ShowErrorMessage('Invalid Day, Month or Year Range Detected.');
      const elementFound = this.EditTaskInfo.nativeElement.querySelector('[name="' + 'TaskAddNewExpectedDeliveryDatename' + '"]');
      if (this.hasValue(elementFound)) {
        elementFound.children[0].children[0].children[0].children[0].value = "";
      }
      // this.TaskAddInfoModel.ExpectedDate = undefined;
      return;
    }

    // if (this.hasValue(this.TaskAddInfoModel.ExpectedDate) && this.TaskAddInfoModel.ExpectedDate <= 0) {
    //   this.TaskAddInfoModel.ExpectedDate = '';
    //   return;
    // }
    var today = new Date();
    var expectedDate = new Date(this.taskEditInfoModel.RequiredByDate);
    const daysDifference = this.DateDiffInDays(today, this.taskEditInfoModel.RequiredByDate);
    const yearsDifference = expectedDate.getFullYear() - today.getFullYear();
    // if (this.hasValue(daysDifference) && daysDifference > 0) {
    //   this.ShowErrorMessage('Expected Delivery Date should be greater than or equal to current date.');
    //   const elementFound = this.CreateTaskInfo.nativeElement.querySelector('[name="' + 'TaskAddNewExpectedDeliveryDatename' + '"]');
    //   if (this.hasValue(elementFound)) {
    //     elementFound.children[0].children[0].children[0].children[0].focus();
    //   }
    //   return;
    // }
    if (this.hasValue(yearsDifference) && yearsDifference > 100) {
      this.ShowErrorMessage('Invalid Day, Month or Year Range Detected.');
      const elementFound = this.EditTaskInfo.nativeElement.querySelector('[name="' + 'TaskAddNewExpectedDeliveryDatename' + '"]');
      if (this.hasValue(elementFound)) {
        elementFound.children[0].children[0].children[0].children[0].focus();
      }
      return;
    }

    if (this.createdDateGreaterThanRequiredByDate()) {
      this.ShowErrorMessage('Required By Delivery Date Should be Greater than or Equal to Created Date');
      // const elementFound = this.EditTaskInfo.nativeElement.querySelector('[name="' + 'EditTaskExpectedDeliveryDatename' + '"]');
      // if (this.hasValue(elementFound)) {
      //   elementFound.children[0].children[0].children[0].children[0].focus();
      // }
      return;
    }
  }

  private isDateInPast(dateToCheck) {
    const daysDifference = this.DateDiffInDays(new Date(), dateToCheck);
    return this.hasValue(daysDifference) && daysDifference > 0;
  }

  // /**
  //  * Process the files uploaded by the user
  //  * @author Sanjay Idpuganti
  //  */
  // processUploadedFiles() {
  //   return new Promise((resolve, reject) => {
  //     if (this.hasValue(this.uploadedFiles) && this.uploadedFiles.length > 0) {

  //       for (let uploadedFileIndex = 0; uploadedFileIndex < this.uploadedFiles.length; uploadedFileIndex++) {

  //         const uploadedFile = this.uploadedFiles[uploadedFileIndex];

  //         if (!uploadedFile.validationErrors) {
  //           const reader = new FileReader();

  //           // asyncronously triggered when file loads
  //           reader.onload = (ev) => {
  //             const UploadedFileSrc = (ev.target as any).result;
  //             const UplaodedFileInBase64Format = window.btoa(unescape(encodeURIComponent(UploadedFileSrc.split('base64,')[1])));

  //             let FileType = 0;
  //             if (uploadedFile.extension.toLocaleLowerCase() === '.png') {
  //               FileType = 2;
  //             } else if (uploadedFile.extension.toLocaleLowerCase() === '.jpeg') {
  //               FileType = 3;
  //             } else if (uploadedFile.extension.toLocaleLowerCase() === '.jpg') {
  //               FileType = 4;
  //             }

  //             this.AttachmentsModel = {
  //               AttachmentBase64Format: UplaodedFileInBase64Format,
  //               AttachmentType: FileType,
  //             };

  //             this.AttachmentsModelList.push(this.AttachmentsModel);

  //             if (uploadedFileIndex === this.uploadedFiles.length - 1) {
  //               // when all files are processed (i.e) pushed to array stop the promise
  //               resolve('All files processed');
  //             }
  //           };

  //           reader.readAsDataURL(uploadedFile.rawFile);
  //         }
  //       }
  //     } else {
  //       resolve('No files to process');
  //     }
  //   });


  // }

  onAdditionalResponsiblePersonsChange() {
    this.selectedAdditionalResponsiblePersons = this.employeeService
      .filterDuplicateEmployees(this.selectedAdditionalResponsiblePersons);
  }

  onObserversChange() {
    this.selectedObservers = this.employeeService
      .filterDuplicateEmployees(this.selectedObservers);
  }

  public selectEventHandler(e: SelectEvent): void {
    this.uploadStateService.setUploadState(UploadState.uploadInProgress);
    this.filesCount += e.files.length;
  }

  public completeEventHandler(event: Event) {
    this.uploadStateService.setUploadState(UploadState.uploadComplete);
  }

  public removeEventHandler(event: RemoveEvent) {
    this.filesCount--;
    if (this.filesCount === 0) {
      this.uploadStateService.setUploadState(UploadState.removedAllFiles);
    }
  }

  cancelEventHandler(event: CancelEvent) {
    this.filesCount--;
    if (this.filesCount === 0) {
      this.uploadStateService.setUploadState(UploadState.removedAllFiles);
    }
  }


  private createdDateGreaterThanRequiredByDate(): boolean {
    //console.log(this.taskEditInfoModel.RequiredByDate);
    if (this.hasValue(this.taskEditInfoModel.RequiredByDate)) {

      const date1 = new Date(this.taskEditInfoModel.CreatedDate);
      date1.setHours(0, 0, 0);

      return date1 > new Date(this.taskEditInfoModel.RequiredByDate);
    }
  }



  private processpdfAttachments(): Promise<Array<any>> {


    return new Promise(async (resolve) => {

      this.pdfAttachments.map(async pdfAttachment => await this.attachmentService.convertPdfToAttachmentModel(pdfAttachment));

      const convertedPdfAttachments = [];
      for (const pdfAttachment of this.pdfAttachments) {
        //const convertedPdfAttachment = await this.attachmentService.convertPdfToAttachmentModel(pdfAttachment);
        convertedPdfAttachments.push(pdfAttachment);
      }

      resolve(convertedPdfAttachments);
    });

  }






  EditTaskmoduleIconClickEvent() {
    const dialogRef = this.dialogService.open({
      title: 'Select Module',
      // Show component
      content: SelectModulePopupComponentComponent,
      height: 698,
      width: 550,
      appendTo: this.ContainerRef
    });
    dialogRef.result.subscribe((result) => {
      //debugger;
      if (result instanceof DialogCloseResult) {
        if (this.hasValue(this.selectedModule)) {

        } else {
          this.selectedModule = '';
        }
      } else {
        this.ModuleListInfo = result;
        if (this.hasValue(this.ModuleListInfo)) {
          // console.log(this.ModuleListInfo)
          this.selectedModule = this.ModuleListInfo;
          this.taskEditInfoModel.ModuleId = this.ModuleListInfo.IssueSupportGroupID;
          this.taskEditInfoModel.ModuleName = this.ModuleListInfo.IssueSupportGroupDesc;
        }
      }
    });
  }

  EditTaskRemoveModuleInfo() {
    this.selectedModule = undefined;
    this.taskEditInfoModel.ModuleId = undefined;
    this.taskEditInfoModel.ModuleName = '';
  }

  private formatValue(value?: Date): string {
    return value ? `${this.intl.formatDate(value, 'MM/dd/yyyy')}` : '';
  }

  async UpdateTaskDetails() {

    if (!this.hasValue(this.selectedModule) || !this.hasValue(this.selectedModule.IssueSupportGroupID)) {
      this.ShowErrorMessage('Please Select Module');
      const elementFound = this.EditTaskInfo.nativeElement.querySelector('[name="' + 'EditTaskModuleName' + '"]');
      if (this.hasValue(elementFound)) {
        elementFound.children[0].focus();
      }
      return;
    }

    if (!this.hasValue(this.taskEditInfoModel.IssueSubject)) {
      this.ShowErrorMessage('Please Enter Task Subject');
      this.EditTaskInfo.nativeElement.querySelector('#EditTaskSubject').focus();
      return;
    }
    if (!this.hasValue(this.selectedTaskCurrentWorkingStatus.Task_Current_Working_Status_InfoID)) {
      this.ShowErrorMessage('Please Select Current Working Location');
      //this.CreateTaskInfo.nativeElement.querySelector('#TaskAddNewCurrentWokringStatus').focus();
      const elementFound = this.EditTaskInfo.nativeElement.querySelector('[name="' + 'EditTaskCurrentStatus' + '"]');
      if (this.hasValue(elementFound)) {
        elementFound.children[0].focus();
      }
      return;
    }
    if (!this.hasValue(this.taskEditInfoModel.SelectedTaskBody)) {
      this.ShowErrorMessage('Please Enter Task Details');
      const elementFound = this.EditTaskInfo.nativeElement.querySelector('[name="' + 'EditTaskDetails' + '"]');
      if (this.hasValue(elementFound)) {
        elementFound.children[1].children[0].focus();
      }
      return;
    }

    if (!this.hasValue(this.responsiblePerson) || !this.hasValue(this.responsiblePerson.EmployeeId)) {
      this.ShowErrorMessage('Please Select Responsible Person');
      const elementFound = this.EditTaskInfo.nativeElement.querySelector('[name="' + 'EditTaskResponsiblePersonName' + '"]');
      if (this.hasValue(elementFound)) {
        elementFound.children[0].focus();
      }
      return;
    }
    if (!this.hasValue(this.SelectedQAPersonInfo) || !this.hasValue(this.SelectedQAPersonInfo.EmployeeId)) {
      this.ShowErrorMessage('Please Select QA Person');
      const elementFound = this.EditTaskInfo.nativeElement.querySelector('[name="' + 'TaskEditNewQAPerson' + '"]');
      if (this.hasValue(elementFound)) {
        elementFound.children[0].focus();
      }
      return;
    }
    //
    // if (!this.hasValue(this.responsiblePerson) || !this.hasValue(this.responsiblePerson.EmployeeId)) {
    //   this.ShowErrorMessage('Please Select Responsible Person');
    //   const elementFound = this.EditTaskInfo.nativeElement.querySelector('[name="' + 'EditTaskResponsiblePersonName' + '"]');
    //   if (this.hasValue(elementFound)) {
    //     elementFound.children[0].focus();
    //   }
    //   return;
    // }
    // if (!this.hasValue(this.responsiblePerson) || !this.hasValue(this.responsiblePerson.EmployeeId)) {
    //   this.ShowErrorMessage('Please Select Responsible Person');
    //   const elementFound = this.EditTaskInfo.nativeElement.querySelector('[name="' + 'EditTaskResponsiblePersonName' + '"]');
    //   if (this.hasValue(elementFound)) {
    //     elementFound.children[0].focus();
    //   }
    //   return;
    // }
    // if (!this.hasValue(this.responsiblePerson) || !this.hasValue(this.responsiblePerson.EmployeeId)) {
    //   this.ShowErrorMessage('Please Select Responsible Person');
    //   const elementFound = this.EditTaskInfo.nativeElement.querySelector('[name="' + 'EditTaskResponsiblePersonName' + '"]');
    //   if (this.hasValue(elementFound)) {
    //     elementFound.children[0].focus();
    //   }
    //   return;
    // }
    // if (!this.hasValue(this.responsiblePerson) || !this.hasValue(this.responsiblePerson.EmployeeId)) {
    //   this.ShowErrorMessage('Please Select Responsible Person');
    //   const elementFound = this.EditTaskInfo.nativeElement.querySelector('[name="' + 'EditTaskResponsiblePersonName' + '"]');
    //   if (this.hasValue(elementFound)) {
    //     elementFound.children[0].focus();
    //   }
    //   return;
    // }//

    

    if (this.hasValue(this.taskEditInfoModel.TaskCamefromType)) {
      this.taskEditInfoModel.TaskCamefromType = this.taskEditInfoModel.TaskCamefromType.editSelectTaskFromListTextValue;
    }

    if (this.createdDateGreaterThanRequiredByDate()) {
      this.ShowErrorMessage('Required By Delivery Date Should be Greater than or Equal to Created Date');
      const elementFound = this.EditTaskInfo.nativeElement.querySelector('[name="' + 'EditTaskExpectedDeliveryDatename' + '"]');
      if (this.hasValue(elementFound)) {
        elementFound.children[0].children[0].children[0].children[0].focus();
      }
      return;
    }

    if (!this.uploadStateService.isValidState()) {
      this.ShowErrorMessage('Please Wait till the files are uploaded');
      return;
    }

    // wait until all the files are processed
    let attachmentsContainer = await this.attachmentService.processUploadAttachments(this.uploadedFiles);

    if (this.attachmentService.duplicatesExists(attachmentsContainer as Array<Attachment>)) {
      this.ShowErrorMessage('Duplicates Exists in Uploaded Attachments Please Try Again.');
      this.uploadedFiles = [];
      return;
    }

    if (!this.attachmentService.uploadedAttachmentsCompatable(attachmentsContainer)) {
      this.ShowErrorMessage('Uploaded attachments contain files other than JPG,JPEG,PNG,DocX,Doc,XLSX,XLs and PDF files.');
      this.uploadedFiles = [];
      return;
    }

    if (this.hasValue(this.taskEditInfoModel.SelectedTaskBody)) {

      const body = {
        //upded
        AttachmentBase64Format: window.btoa(unescape(encodeURIComponent(this.taskEditInfoModel.SelectedTaskBody))),
        AttachmentType: 1,

        //previuous
        AttachmentId:this.taskBodyList[0].AttachmentId
      }
      attachmentsContainer.push(body);
    }



    if (this.hasValue(this.AttachmentsList)) {
      const attachmentModels = this.AttachmentsList.map(attachment => this.attachmentService.convertToAttachmentModel(attachment));
      // const pdfAttachmentModels = await this.processpdfAttachments();

      attachmentsContainer = attachmentsContainer.concat(attachmentModels);//.concat(pdfAttachmentModels);
    }



    if (this.attachmentService.duplicatesExists(attachmentsContainer as Array<Attachment>)) {
      this.ShowErrorMessage('Duplicates Exists in Uploaded Attachments Please Try Again.');
      this.uploadedFiles = [];
      return;
    }

      if (this.hasValue(this.pdfAttachments)) {

        //const pdfAttachmentModels = await this.processpdfAttachments();
        attachmentsContainer = attachmentsContainer.concat(this.pdfAttachments);

      }

    // if(this.hasValue(this.pdfAttachments) && this.pdfAttachments.length > 0){

    //  for (const eachattachment of this.pdfAttachments) {

    //  attachmentsContainer.push(eachattachment);
    //}

    //}

    // if (!this.hasValue(this.selectedProject) || !this.hasValue(this.selectedProject.ProjectId)) {
    //   this.ShowErrorMessage('Please Select Project');
    //   const elementFound = this.EditTaskInfo.nativeElement.querySelector('[name="' + 'EditTaskProjectName' + '"]');
    //   if (this.hasValue(elementFound)) {
    //     elementFound.children[0].focus();
    //   }
    //   return;
    // }


    var today = new Date();
    var expectedDate = new Date(this.taskEditInfoModel.RequiredByDate);
    const yearsDifference = expectedDate.getFullYear() - today.getFullYear();

    if (this.hasValue(yearsDifference) && yearsDifference > 100) {
      this.ShowErrorMessage('Specify a valid Required By Delivery Date.');
      const elementFound = this.EditTaskInfo.nativeElement.querySelector('[name="' + 'EditTaskExpectedDeliveryDatename' + '"]');
      if (this.hasValue(elementFound)) {
        elementFound.children[0].children[0].children[0].children[0].focus();
      }
      return;
    }

    // if (this.hasValue(this.taskEditInfoModel.CreatedDate) && this.isDateInPast(this.taskEditInfoModel.CreatedDate)) {
    //   this.ShowErrorMessage('Expected Delivery Date Should be Greater than or Equal to Created Date');
    //   const elementFound = this.EditTaskInfo.nativeElement.querySelector('[name="' + 'EditTaskExpectedDeliveryDatename' + '"]');
    //   if (this.hasValue(elementFound)) {
    //     elementFound.children[0].children[0].children[0].children[0].focus();
    //   }
    //   return;
    // }

    if (this.hasValue(this.SelectedAssistantPerson) && this.SelectedAssistantPerson.length > 0) {
      this.SelectedAssistantPerson.forEach((selectedRow, index) => {
        if (this.responsiblePerson.EmployeeId == selectedRow.EmployeeId) {
          this.IsresponsiblePersonAndFwdToSame = true;
        }
      });
    }

    // if (this.IsresponsiblePersonAndFwdToSame == true) {
    //   this.ShowErrorMessage('Responsible Person and Forward To Should not be Same.');
    //   this.IsresponsiblePersonAndFwdToSame = false;
    //   return;
    // }




    if (this.hasValue(this.selectedAdditionalResponsiblePersons) && this.selectedAdditionalResponsiblePersons.length > 0) {
      this.selectedAdditionalResponsiblePersons.forEach(element => {

        const EmployeesAndRolesModel = {
          EmployeeId: element.EmployeeId,
          RoleID: 4,
        };

        this.EmployeesAndRolesModelList.push(EmployeesAndRolesModel);
      });

      // this.selectedAdditionalResponsiblePersons
      //   .map(participant => ({ EmployeeId: participant.EmployeeId, RoleID: participant.RoleID }))
      //   .forEach(participant => this.EmployeesAndRolesModelList.push(participant));
    }

    if (this.hasValue(this.selectedObservers) && this.selectedObservers.length > 0) {
      this.selectedObservers.forEach(element => {
        const EmployeesAndRolesModel = {
          EmployeeId: element.EmployeeId,
          RoleID: 3,
        };

        this.EmployeesAndRolesModelList.push(EmployeesAndRolesModel);
      });
    }


    // console.log(this.TaskAddInfoModel);
    if (this.hasValue(this.responsiblePerson)) {
      const EmployeesAndRolesModel = {
        EmployeeId: this.responsiblePerson.EmployeeId,
        RoleID: 2,
      };
      this.EmployeesAndRolesModelList.push(EmployeesAndRolesModel);
    }

    if (this.hasValue(this.SelectedQAPersonInfo.EmployeeId)) {
      const EmployeesAndRolesModel = {
        EmployeeId: this.SelectedQAPersonInfo.EmployeeId,
        RoleID: 8,
      };
      this.EmployeesAndRolesModelList.push(EmployeesAndRolesModel);
    }
    if (this.hasValue(this.SelectedCSharpPersonInfo.EmployeeId)) {
      const EmployeesAndRolesModel = {
        EmployeeId: this.SelectedCSharpPersonInfo.EmployeeId,
        RoleID: 9,
      };
      this.EmployeesAndRolesModelList.push(EmployeesAndRolesModel);
    }
    if (this.hasValue(this.SelectedDBPersonInfo.EmployeeId)) {
      const EmployeesAndRolesModel = {
        EmployeeId: this.SelectedDBPersonInfo.EmployeeId,
        RoleID: 10,
      };
      this.EmployeesAndRolesModelList.push(EmployeesAndRolesModel);
    }
    if (this.hasValue(this.SelectedJSPersonInfo.EmployeeId)) {
      const EmployeesAndRolesModel = {
        EmployeeId: this.SelectedJSPersonInfo.EmployeeId,
        RoleID: 11,
      };
      this.EmployeesAndRolesModelList.push(EmployeesAndRolesModel);
    }

    // if (this.hasValue(this.assistantPerson) && this.assistantPerson.length > 0) {
    //   this.assistantPerson.forEach(Assistantselement => {
    //     const EmployeesAndRolesModel = {
    //       EmployeeId: Assistantselement.EmployeeId,
    //       RoleID: 7,
    //     };

    //     this.EmployeesAndRolesModelList.push(EmployeesAndRolesModel);
    //   });
    //   // const EmployeesAndRolesModel = {
    //   //   EmployeeId: this.assistantPerson.EmployeeId,
    //   //   RoleID: 7,
    //   // };
    //   // this.EmployeesAndRolesModelList.push(EmployeesAndRolesModel);
    // }

    if (this.hasValue(this.SelectedAssistantPerson) && this.SelectedAssistantPerson.length > 0) {
      this.SelectedAssistantPerson.forEach(Assistantselement => {
        const EmployeesAndRolesModel = {
          EmployeeId: Assistantselement.EmployeeId,
          RoleID: 7,
        };

        this.EmployeesAndRolesModelList.push(EmployeesAndRolesModel);
      });
    }

    if (this.EmployeesAndRolesModelList.length > 0) {
      this.taskEditInfoModel.EmployeesAndRolesModelList = this.EmployeesAndRolesModelList;
    }

    if (attachmentsContainer.length > 0) {
      this.taskEditInfoModel.AttachmentsModelList = attachmentsContainer;
    }

    if (this.hasValue(this.selectedTaskType)) {
      this.SelectedTaskTypeInfo = this.selectedTaskType.TaskTypeId;
    }
    else {
      this.SelectedTaskTypeInfo = undefined;
    }
    //console.log(this.taskEditInfoModel.RequiredByDate);
    const postData = {
      EmployeesAndRolesModelList: this.taskEditInfoModel.EmployeesAndRolesModelList,
      IssueSubject: this.taskEditInfoModel.IssueSubject,
      TaskInfoID: this.taskEditInfoModel.id,
      AttachmentsModelList: this.taskEditInfoModel.AttachmentsModelList,
      //TaskChangeEvents: this.calculateTaskChanges(),
      TaskTypeId: this.SelectedTaskTypeInfo,
      EHRPracticeID: this.SelectedPracticeInfo.PracticeId,
      IsPublicTask: this.taskEditInfoModel.IsPublicTask,
      IsTaskCreatedfromEHR: false,
      IsTaskFrom:1,
      IsAttachmentUpload:true
    } as any;

    postData.TaskChangeEvents = this.calculateTaskChanges();

    if (this.hasValue(this.taskEditInfoModel.TaskCamefromType)) {
      postData.TaskCamefromType = this.taskEditInfoModel.TaskCamefromType
    }

    // if (this.IsAssistantPersonInfoChanged == true) {
    //   const taskChangeEvent: TaskChangeEvent = {
    //     EventId: TaskEventTypeEnum.TaskForwardTo,
    //     TaskInfoId: this.taskEditInfoModel.id,
    //     Description: this.populateForwardToChangeDescription()
    //   };
    //   if (this.hasValue(postData.taskChangeEvents)) {
    //     postData.taskChangeEvents.push(taskChangeEvent);
    //   }
    //   else {
    //     postData.taskChangeEvents = [];
    //     postData.taskChangeEvents.push(taskChangeEvent);
    //   }
    // }

    if (this.selectedSeverity && this.selectedSeverity.IssueSeverityID) {
      postData.IssueSeverityID = this.selectedSeverity.IssueSeverityID;
    }

    if (this.hasValue(this.taskEditInfoModel.RequiredByDate)) {
      this.taskEditInfoModel.RequiredByDate = this.formatValue(this.taskEditInfoModel.RequiredByDate)
    }

    //console.log(this.taskEditInfoModel.RequiredByDate);

    if (this.hasValue(this.taskEditInfoModel.RequiredByDate)) {
      postData.RequiredByDate = this.taskEditInfoModel.RequiredByDate;
    }

    if (this.selectedProject && this.selectedProject.ProjectId) {
      postData.ProjectId = this.selectedProject.ProjectId;
    }

    if (this.selectedModule && this.selectedModule.IssueSupportGroupID) {
      postData.IssueSupportGroupID = this.selectedModule.IssueSupportGroupID;
    }


    if (this.hasValue(this.selectedTaskCurrentWorkingStatus)) {
      postData.Task_Current_Working_Status_InfoID = this.selectedTaskCurrentWorkingStatus.Task_Current_Working_Status_InfoID;
    }

    //
    // setTimeout(() => {
    this.commonhelperobj.PostData(Config.OASAppnURL + 'SaveReportOASIssueDetails', postData).subscribe(serviceResponse => {
      if (this.isError(serviceResponse)) {
        this.taskEditInfoModel.EmployeesAndRolesModelList = [];
        return;
      }

      this.ShowSuccesseMessage('Task Details Saved SuccessFully.');
      this.sendInternalTicketNotificationtoEmployees();
      this.taskEditInfoModel = {};
      this.EmployeesAndRolesModelList = [];
      this.selectedObservers = undefined;
      this.taskEditInfoModel.EmployeesAndRolesModelList = [];
      this.selectedAdditionalResponsiblePersons = undefined;
      this.selectedObservers = undefined;
      // this.responsiblePerson = undefined;

      // this.router.navigate();
      const outlet = {};
      const clickedItem2: any = {};
      clickedItem2.routename = 'main';
      clickedItem2.routepath = '/home';
      //clickedItem2.previouslySelectedPageNumber=this.EditInfo.EmployeeTaskListRecordCount
      outlet[clickedItem2.routename] = ['app-view-task-information', this.setCommonData(clickedItem2)];
      this.router.navigate([clickedItem2.routepath, { outlets: outlet }]);

    });
    // }, 100);
  }

  CancelClickEvent() {
    // this.router.navigate();
    const outlet = {};
    const clickedItem2: any = {};
    clickedItem2.routename = 'main';
    clickedItem2.routepath = '/home';
    outlet[clickedItem2.routename] = ['app-view-task-information', this.setCommonData(clickedItem2)];
    this.router.navigate([clickedItem2.routepath, { outlets: outlet }]);
  }

  /**
   * TODO: Replace it with data from database
   * Return id of severity
   * @param severityDescription from description string return it's respective id
   */
  private calculateSeverityId(severityDescription: 'High' | 'Normal' | 'Low') {
    if (severityDescription === 'High') {
      return 1;
    } else if (severityDescription === 'Normal') {
      return 2;
    } else if (severityDescription === 'Low') {
      return 3;
    }
    else if (severityDescription === 'Urgent') {
      return 4;
    }
  }

  /**
   * Return date object from mm/dd/yyyy format
   * @param dateString date in string data type with format mm/dd/yyyy
   * @author Sanjay Idpuganti
   */
  private parseDate(dateString: string): Date {
    if (!this.hasValue(dateString) || dateString.indexOf('/') === dateString.lastIndexOf('/')) {
      return undefined;
    }

    const [month, day, year] = dateString.split('/').map(dateFragment => +dateFragment);
    return new Date(year, month - 1, day);
  }

  private byName(employee1, employee2) {
    if (employee1.EmployeeFullName < employee2.EmployeeFullName) {
      return -1;
    }
    if (employee1.EmployeeFullName > employee2.EmployeeFullName) {
      return 1;
    }
    return 0;
  }

  private populateObserversChangeDescription(): string {
    if (this.hasValue(this.selectedObservers) && this.selectedObservers.length > 0) {

      const { responsiblePerson, taskObservers, taskParticipants, taskAssistant } = this.employeeService.parseEmployeeRoles(this.TaskUserInfoinEditMode+",");
      this.PreviousselectedObservers = taskObservers;
      // let description = "Task Forward To ";
      let description =
        this.PreviousselectedObservers
          .sort(this.byName)
          .map(observer => `${observer.EmployeeId} | ${observer.EmployeeFullName}`)
          .join(',');

      description += ' --> ';

      description += this.selectedObservers
        .sort(this.byName)
        .map(observer => `${observer.EmployeeId} | ${observer.EmployeeFullName}`)
        .join(',');

      return description;
    }
    // let description =
    //   this.initialObservers
    //     .sort(this.byName)
    //     .map(observer => `${observer.EmployeeId} | ${observer.EmployeeFullName}`)
    //     .join(',');

    // description += ' --> ';

    // description += this.selectedObservers
    //   .sort(this.byName)
    //   .map(observer => `${observer.EmployeeId} | ${observer.EmployeeFullName}`)
    //   .join(',');

    // return description;
  }


  private populateParticipantsChangeDescription(): string {
    if (this.hasValue(this.selectedAdditionalResponsiblePersons) && this.selectedAdditionalResponsiblePersons.length > 0) {

      const { responsiblePerson, taskObservers, taskParticipants, taskAssistant,
        taskQAPerson, taskCSharpPerson, taskDbPerson, taskJsPerson, } = this.employeeService.parseEmployeeRoles(this.TaskUserInfoinEditMode+",");
      this.PreviousselectedParticipants = taskParticipants;
      // let description = "Task Forward To ";
      let description =
        this.PreviousselectedParticipants
          .sort(this.byName)
          .map(Participants => `${Participants.EmployeeId} | ${Participants.EmployeeFullName}`)
          .join(',');

      description += ' --> ';

      description += this.selectedAdditionalResponsiblePersons
        .sort(this.byName)
        .map(Participants => `${Participants.EmployeeId} | ${Participants.EmployeeFullName}`)
        .join(',');

      return description;
    }
    // let description =
    //   this.initialParticipants
    //     .sort(this.byName)
    //     .map(participant => `${participant.EmployeeId} | ${participant.EmployeeFullName}`)
    //     .join(',');

    // description += ' --> ';

    // description += this.selectedAdditionalResponsiblePersons
    //   .sort(this.byName)
    //   .map(participant => `${participant.EmployeeId} | ${participant.EmployeeFullName}`)
    //   .join(',');

    // return description;
  }

  private populateResponsiblePersonChangeDescription(): string {
    if (this.hasValue(this.responsiblePerson)) {
      return `${this.initialResponsiblePerson.EmployeeId} | ${this.initialResponsiblePerson.EmployeeFullName}` +
        ` --> ${this.responsiblePerson.EmployeeId} | ${this.responsiblePerson.EmployeeFullName}`;
    }
    // return `${this.initialResponsiblePerson.EmployeeId} | ${this.initialResponsiblePerson.EmployeeFullName}` +
    //   ` --> ${this.responsiblePerson.EmployeeId} | ${this.responsiblePerson.EmployeeFullName}`;
  }

  
  private populateQAPersonChangeDescription(): string {
    if (this.hasValue(this.SelectedQAPersonInfo)) {
      return `${this.initialQAPerson.EmployeeId} | ${this.initialQAPerson.EmployeeFullName}` +
        ` --> ${this.SelectedQAPersonInfo.EmployeeId} | ${this.SelectedQAPersonInfo.EmployeeFullName}`;
    }
  }

  private populateCSharpPersonChangeDescription(): string {
    if (this.hasValue(this.SelectedCSharpPersonInfo)) {
      return `${this.initialCSharpPerson.EmployeeId} | ${this.initialCSharpPerson.EmployeeFullName}` +
        ` --> ${this.SelectedCSharpPersonInfo.EmployeeId} | ${this.SelectedCSharpPersonInfo.EmployeeFullName}`;
    }
  }

  private populateDBPersonChangeDescription(): string {
    if (this.hasValue(this.SelectedDBPersonInfo)) {
      return `${this.initialDBPerson.EmployeeId} | ${this.initialDBPerson.EmployeeFullName}` +
        ` --> ${this.SelectedDBPersonInfo.EmployeeId} | ${this.SelectedDBPersonInfo.EmployeeFullName}`;
    }
  }

  private populateJSPersonChangeDescription(): string {
    if (this.hasValue(this.SelectedJSPersonInfo)) {
      return `${this.initialJSPerson.EmployeeId} | ${this.initialJSPerson.EmployeeFullName}` +
        ` --> ${this.SelectedJSPersonInfo.EmployeeId} | ${this.SelectedJSPersonInfo.EmployeeFullName}`;
    }
  }

  private populateDeadlineChangeDescription(): string {
    if (this.initialDeadlineDate && !this.taskEditInfoModel.RequiredByDate) {
      return `Removed Deadline`;
    }

    if (!this.initialDeadlineDate && this.taskEditInfoModel.RequiredByDate) {
      return `Set Deadline on ${this.taskEditInfoModel.RequiredByDate.toDateString()}`;
    }

    return ` Deadline Changed to ${this.taskEditInfoModel.RequiredByDate.toDateString()}`;
    // ` --> ${this.taskEditInfoModel.RequiredByDate.toDateString()}`;

    // return `${this.initialDeadlineDate.toDateString()}' +
    // ' --> ${this.taskEditInfoModel.RequiredByDate.toDateString()}`;
  }

  private populateModuleChangeEventDescription(): string {

    if (!this.initialModule && this.selectedModule.IssueSupportGroupDesc) {
      return `Set ${this.selectedModule.IssueSupportGroupDesc} as module`;
    }

    if (this.initialModule && !this.selectedModule) {
      return `Removed ${this.initialModule} as module`;
    }

    return `${this.initialModule.IssueSupportGroupID} | ${this.initialModule.IssueSupportGroupDesc}` +
      ` --> ${this.selectedModule.IssueSupportGroupID} | ${this.selectedModule.IssueSupportGroupDesc}`;
  }

  private populateProjectChangeEventDescription(): string {
    if (!this.initialProject && this.selectedProject.Description) {
      return `Set ${this.selectedProject.Description} as project`;
    }

    if (this.initialProject && !this.selectedProject) {
      return `Removed ${this.initialProject} as module`;
    }

    return `${this.initialProject.ProjectId} | ${this.initialProject.Description}` +
      ` --> ${this.selectedProject.ProjectId} | ${this.selectedProject.Description}`;
  }

  private populateTaskBodyChangeEventDescription(): string {
    return 'Task Body Changed';
  }

  private populateSeverityChangeEventDescription(): string {

    if (!this.initialSeverity) {
      return `Set ${this.selectedSeverity.IssueSeverityDesc} as Severity`;
    }

    if (!this.selectedSeverity) {
      return `Removed ${this.initialSeverity.IssueSeverityDesc} as Severity`;
    }

    return `${this.initialSeverity.IssueSeverityID} | ${this.initialSeverity.IssueSeverityDesc}` +
      ` --> ${this.selectedSeverity.IssueSeverityID} | ${this.selectedSeverity.IssueSeverityDesc}`;
  }

  private populateCurrentStatusChangeEventDescription(): string {

    if (this.selectedTaskCurrentWorkingStatus) {
      return `Set ${this.selectedTaskCurrentWorkingStatus.Task_Current_Working_Status} as Task Current Working Status`;
    }

    // if (this.selectedSeverity) {
    //   return `Removed ${this.initialSeverity.IssueSeverityDesc} as Severity`;
    // }

    // return `${this.initialSeverity.IssueSeverityID} | ${this.initialSeverity.IssueSeverityDesc}` +
    //   ` --> ${this.selectedSeverity.IssueSeverityID} | ${this.selectedSeverity.IssueSeverityDesc}`;
  }



  private populateFilesChangeEventDescription(): string {
    let description = '';

    if (this.fileUploaded && this.fileDeleted) {
      description = 'Attachment(s) uploaded and deleted';
    } else if (this.fileDeleted) {
      description = 'Attachment(s) deleted';
    } else if (this.fileUploaded) {
      description = 'Attachment(s) uploaded';
    }

    return description;
  }


  private calculateTaskChanges(): Array<TaskChangeEvent> {

    const taskChangeEvents: Array<TaskChangeEvent> = [];

    if (this.observersChanged()) {
      const taskChangeEvent: TaskChangeEvent = {
        EventId: TaskEventTypeEnum.Observers,
        TaskInfoId: this.taskEditInfoModel.id,
        Description: this.populateObserversChangeDescription()
      };

      taskChangeEvents.push(taskChangeEvent);
    }

    if (this.responsiblePersonChanged()) {
      const taskChangeEvent: TaskChangeEvent = {
        EventId: TaskEventTypeEnum.ResponsiblePerson,
        TaskInfoId: this.taskEditInfoModel.id,
        Description: this.populateResponsiblePersonChangeDescription()
      };

      taskChangeEvents.push(taskChangeEvent);
    }

    if (this.participantsChanged()) {
      const taskChangeEvent: TaskChangeEvent = {
        EventId: TaskEventTypeEnum.Participants,
        TaskInfoId: this.taskEditInfoModel.id,
        Description: this.populateParticipantsChangeDescription()
      };

      taskChangeEvents.push(taskChangeEvent);
    }

    if (this.deadlineChanged()) {
      const taskChangeEvent: TaskChangeEvent = {
        EventId: TaskEventTypeEnum.RequiredByDateUpdated,
        TaskInfoId: this.taskEditInfoModel.id,
        Description: this.populateDeadlineChangeDescription(),
      };

      taskChangeEvents.push(taskChangeEvent);
    }

    if (this.projectChanged()) {
      const taskChangeEvent: TaskChangeEvent = {
        EventId: TaskEventTypeEnum.ProjectUpdated,
        TaskInfoId: this.taskEditInfoModel.id,
        Description: this.populateProjectChangeEventDescription(),
      };

      taskChangeEvents.push(taskChangeEvent);
    }

    if (this.moduleChanged()) {
      const taskChangeEvent: TaskChangeEvent = {
        EventId: TaskEventTypeEnum.ModuleUpdated,
        TaskInfoId: this.taskEditInfoModel.id,
        Description: this.populateModuleChangeEventDescription(),
      };

      taskChangeEvents.push(taskChangeEvent);
    }

    if (this.severityChanged()) {
      const taskChangeEvent: TaskChangeEvent = {
        EventId: TaskEventTypeEnum.SeverityUpdated,
        TaskInfoId: this.taskEditInfoModel.id,
        Description: this.populateSeverityChangeEventDescription(),
      };

      taskChangeEvents.push(taskChangeEvent);
    }


    if (this.taskDescriptionChanged()) {
      const taskChangeEvent: TaskChangeEvent = {
        EventId: TaskEventTypeEnum.DescriptionUpdated,
        TaskInfoId: this.taskEditInfoModel.id,
        Description: this.populateTaskBodyChangeEventDescription(),
      };

      taskChangeEvents.push(taskChangeEvent);
    }

    if (this.attachmentsChanged()) {
      const taskChangeEvent: TaskChangeEvent = {
        EventId: TaskEventTypeEnum.Attachments,
        TaskInfoId: this.taskEditInfoModel.id,
        Description: this.populateFilesChangeEventDescription(),
      };

      taskChangeEvents.push(taskChangeEvent);
    }

    if (this.CurrentStatusChanged()) {
      const taskChangeEvent: TaskChangeEvent = {
        EventId: TaskEventTypeEnum.TaskCurrentWorkingStatus,
        TaskInfoId: this.taskEditInfoModel.id,
        Description: this.populateCurrentStatusChangeEventDescription(),
      };

      taskChangeEvents.push(taskChangeEvent);
    }

    
    if (this.responsiblePersonChanged()) {
      const taskChangeEvent: TaskChangeEvent = {
        EventId: TaskEventTypeEnum.ResponsiblePerson,
        TaskInfoId: this.taskEditInfoModel.id,
        Description: this.populateResponsiblePersonChangeDescription()
      };

      taskChangeEvents.push(taskChangeEvent);
    }

    
    if (this.QAPersonChanged()) {
      const taskChangeEvent: TaskChangeEvent = {
        EventId: TaskEventTypeEnum.QAPerson,
        TaskInfoId: this.taskEditInfoModel.id,
        Description: this.populateQAPersonChangeDescription()
      };

      taskChangeEvents.push(taskChangeEvent);
    }

    
    if (this.CSharpPersonChanged()) {
      const taskChangeEvent: TaskChangeEvent = {
        EventId: TaskEventTypeEnum.CSharpPerson,
        TaskInfoId: this.taskEditInfoModel.id,
        Description: this.populateCSharpPersonChangeDescription()
      };

      taskChangeEvents.push(taskChangeEvent);
    }

    
    if (this.DBPersonChanged()) {
      const taskChangeEvent: TaskChangeEvent = {
        EventId: TaskEventTypeEnum.DBPerson,
        TaskInfoId: this.taskEditInfoModel.id,
        Description: this.populateDBPersonChangeDescription()
      };

      taskChangeEvents.push(taskChangeEvent);
    }

    if (this.JSPersonChanged()) {
      const taskChangeEvent: TaskChangeEvent = {
        EventId: TaskEventTypeEnum.JSPerson,
        TaskInfoId: this.taskEditInfoModel.id,
        Description: this.populateJSPersonChangeDescription()
      };

      taskChangeEvents.push(taskChangeEvent);
    }

    return taskChangeEvents;
  }


  // private populateForwardToChangeDescription(): string {
  //   if (this.hasValue(this.SelectedAssistantPerson) && this.SelectedAssistantPerson.length > 0) {

  //     const { responsiblePerson, taskObservers, taskParticipants, taskAssistant } = this.employeeService.parseEmployeeRoles(this.TaskUserInfoinEditMode);
  //     this.PreviousSelectedAssistantPerson = taskAssistant;
  //     // let description = "Task Forward To ";
  //     let description =
  //       this.PreviousSelectedAssistantPerson
  //         .sort(this.byName)
  //         .map(observer => `${observer.EmployeeId} | ${observer.EmployeeFullName}`)
  //         .join(',');

  //     description += ' --> ';

  //     description += this.SelectedAssistantPerson
  //       .sort(this.byName)
  //       .map(observer => `${observer.EmployeeId} | ${observer.EmployeeFullName}`)
  //       .join(',');

  //     return description;
  //   }
  // }


  private attachmentsChanged() {
    return this.fileDeleted || this.fileUploaded;
  }

  // both can be null
  // both can be selected
  // one can be null and other can be not null
  private severityChanged(): boolean {

    if (this.initialSeverity && this.selectedSeverity && this.initialSeverity.IssueSeverityDesc &&
      this.selectedSeverity.IssueSeverityDesc) {
      // both are not null
      return !(this.initialSeverity.IssueSeverityDesc === this.selectedSeverity.IssueSeverityDesc);
    }


    if ((!this.initialSeverity && this.selectedSeverity) || (this.initialSeverity && !this.selectedSeverity)) {
      return true;
    }

    return false;
  }

  private CurrentStatusChanged(): boolean {

    if (this.initialTaskCurrentWorkingStatus && this.selectedTaskCurrentWorkingStatus && this.selectedTaskCurrentWorkingStatus.Task_Current_Working_Status_InfoID
    ) {
      // both are not null
      return !(this.initialTaskCurrentWorkingStatus === this.selectedTaskCurrentWorkingStatus.Task_Current_Working_Status_InfoID);
    }


    if ((!this.initialTaskCurrentWorkingStatus && this.selectedTaskCurrentWorkingStatus) || (this.initialTaskCurrentWorkingStatus && !this.selectedTaskCurrentWorkingStatus)) {
      return true;
    }

    return false;
  }

  private taskDescriptionChanged(): boolean {
    return this.initialTaskBody !== this.taskEditInfoModel.SelectedTaskBody;
  }

  private moduleChanged(): boolean {
    if (this.initialModule && this.selectedModule) {
      return this.initialModule.IssueSupportGroupDesc !== this.selectedModule.IssueSupportGroupDesc;
    }

    if (!this.initialModule && !this.selectedModule) {
      return false;
    }

    if (!this.initialModule && this.selectedModule) {
      return true;
    }

    if (this.initialModule && !this.selectedModule) {
      return true;
    }

    return false;
  }

  private projectChanged(): boolean {
    if (this.initialProject && this.selectedProject) {
      return this.initialProject.Description !== this.selectedProject.Description;
    }

    if (!this.initialProject && !this.selectedProject) {
      return false;
    }

    if (!this.initialProject && this.selectedProject) {
      return true;
    }

    if (this.initialProject && !this.selectedProject) {
      return true;
    }

    return false;
  }

  private deadlineChanged(): boolean {

    if (this.taskEditInfoModel.RequiredByDate && this.initialDeadlineDate) {
      return this.taskEditInfoModel.RequiredByDate.toDateString() !== this.initialDeadlineDate.toDateString();
    }

    if (!this.taskEditInfoModel.RequiredByDate && this.initialDeadlineDate) {
      return true;
    }

    if (this.taskEditInfoModel.RequiredByDate && !this.initialDeadlineDate) {
      return true;
    }

    return false;
  }

  private observersChanged(): boolean {

    // if (this.initialObservers.length !== this.selectedObservers.length) {
    //   return true;
    // }

    // for (const observer of this.initialObservers) {
    //   // undifined when not found
    //   const found = this.selectedObservers.find(o => o.EmployeeFullName === observer.EmployeeFullName);

    //   if (!found) {
    //     return true;
    //   }
    // }

    // for (const observer of this.selectedObservers) {
    //   const found = this.initialObservers.find(o => o.EmployeeFullName === observer.EmployeeFullName);
    //   if (!found) {
    //     return true;
    //   }
    // }

    if (this.IsObserversInfoChanged == true) {
      return true;
    }
    else {
      return false;
    }
  }

  private participantsChanged(): boolean {
    // if (this.initialParticipants.length !== this.selectedAdditionalResponsiblePersons.length) {
    //   return true;
    // }

    // for (const participant of this.initialParticipants) {
    //   // undifined when not found
    //   const found = this.selectedAdditionalResponsiblePersons.find(o => o.EmployeeFullName === participant.EmployeeFullName);

    //   if (!found) {
    //     return true;
    //   }
    // }

    // for (const participant of this.selectedAdditionalResponsiblePersons) {
    //   const found = this.initialParticipants.find(o => o.EmployeeFullName === participant.EmployeeFullName);

    //   if (!found) {
    //     return true;
    //   }
    // }
    if (this.IsAdditionalResponsiblePersonInfoChanged == true) {
      return true;
    }
    else {
      return false;
    }
  }

  private responsiblePersonChanged(): boolean {
    if (this.hasValue(this.responsiblePerson)) {
      return this.previousResponsibleperson.EmployeeId !== this.responsiblePerson.EmployeeId;
    }
    //return +this.initialResponsiblePerson.EmployeeId !== +this.responsiblePerson.EmployeeId;
  }

  private QAPersonChanged(): boolean {
    if (this.hasValue(this.SelectedQAPersonInfo)) {
      return this.previousQAperson.EmployeeId !== this.SelectedQAPersonInfo.EmployeeId;
    }
    //return +this.initialResponsiblePerson.EmployeeId !== +this.responsiblePerson.EmployeeId;
  }

  private CSharpPersonChanged(): boolean {
    if (this.hasValue(this.SelectedCSharpPersonInfo)) {
      return this.previousCSharpperson.EmployeeId !== this.SelectedCSharpPersonInfo.EmployeeId;
    }
    //return +this.initialResponsiblePerson.EmployeeId !== +this.responsiblePerson.EmployeeId;
  }

  
  private DBPersonChanged(): boolean {
    if (this.hasValue(this.SelectedDBPersonInfo)) {
      return this.previousDBperson.EmployeeId !== this.SelectedDBPersonInfo.EmployeeId;
    }
    //return +this.initialResponsiblePerson.EmployeeId !== +this.responsiblePerson.EmployeeId;
  }

  
  private JSPersonChanged(): boolean {
    if (this.hasValue(this.SelectedJSPersonInfo)) {
      return this.previousJSperson.EmployeeId !== this.SelectedJSPersonInfo.EmployeeId;
    }
    //return +this.initialResponsiblePerson.EmployeeId !== +this.responsiblePerson.EmployeeId;
  }

  onDateChange(event) {

  }


  // Helper functions to simplify arrow function body code

  private assignToSelectedProject(project) {
    this.selectedProject = this.initialProject = project;
  }

  private assignToSelectedModule(mdl) {
    this.selectedModule = this.initialModule = mdl;
    // console.log(this.selectedModule)
    this.taskEditInfoModel.ModuleName = this.selectedModule.IssueSupportGroupDesc;
  }

  private assignToSelectedSeverity(severity) {
    this.selectedSeverity = this.initialSeverity = severity;
  }

  private assignToSelectedTaskType(taskType) {
    this.selectedTaskType = this.intialTaskType = taskType;
  }



  private checkForErrorAndAssignToModuleListAndReturn(response) {
    // check for response error
    if (this.isError(response)) { return []; }
    // assign to respective list
    this.moduleList = response;
    // and return
    return this.moduleList;
  }

  private checkForErrorAndAssingToEmployeeListAndReturn(response) {
    // check for response error
    if (this.isError(response)) { return []; }
    // assign to respective list
    this.organizationEmployees = response.EmployeeList;
    // and return
    return this.organizationEmployees;
  }

  private checkForErrorAndAssignToSeverityListAndReturn(response) {
    // check for response error
    if (this.isError(response)) { return []; }
    // assign to respective list
    this.severityList = response;
    // and return
    return this.severityList;
  }

  private checkForErrorAndAssignToCurrentWorkingStatusListAndReturn(response) {
    // check for response error
    if (this.isError(response)) { return []; }
    // assign to respective list
    this.EditNewCurrentWorkingStatusList = response.newCurrentWorkingStatusListModelList;
    // and return
    return this.EditNewCurrentWorkingStatusList;
  }



  private checkForErrorAndAssignToProjectListAndReturn(response) {
    // check for response error
    if (this.isError(response)) { return []; }
    // assign to respective list
    this.projectList = response.ProjectList;
    // and return
    return this.projectList;
  }

  public selectionChange(value: any): void {
    //console.log('selectionChange', value);
  }



  public dropdownclose(): void {

    if (this.FirstTimeLoad === true && !this.hasValue(this.responsiblePerson)) {
      this.responsiblePerson = this.initialResponsiblePerson;
      this.FirstTimeLoad = false;
    }

    //console.log(this.responsiblePerson);
  }


  private checkForErrorAndAssignToTaskTypeList(response) {
    // check for response error
    if (this.isError(response)) {
      return [];
    }
    // assign to respective list
    this.taskTypeList = response;
    // and return
    return this.taskTypeList;
  }

  private GetCurrentStatusList(data) {

    var PostDataToService = {};
    this.commonhelperobj.PostData(Config.OASAppnURL + 'NewCurrentWorkingStatusList', PostDataToService).subscribe(serviceResponse => {
      if (this.isError(serviceResponse)) { return; }
      this.EditNewCurrentWorkingStatusList = serviceResponse.newCurrentWorkingStatusListModelList;

      this.EditNewCurrentWorkingStatusList.forEach((selectedRow, index) => {
        if (data.TaskCurrentWorkingStatusInfoID == selectedRow.Task_Current_Working_Status_InfoID) {
          this.selectedTaskCurrentWorkingStatus = selectedRow;
          return;
        }
      });
    });
  }

  private findSelectedModule(modules: any[], objectToCompare) {
    let a= modules.find(mdl => mdl.IssueSupportGroupID === objectToCompare.SupportGroupID);
    return a;
  }

  private findSelectedProject(projects: any[], objectToCompare) {
    return projects.find(project => project.ProjectId === objectToCompare.ProjectId);
  }

  private findSelectedSeverity(severities: any[], objectToCompare) {
    return severities.find(severity => severity.IssueSeverityDesc === objectToCompare.SeverityDesc);
  }

  private findSelectedTaskType(taskTypes: any[], objectToCompare) {
    return taskTypes.find(taskType => taskType.TaskTypeId === objectToCompare);
  }

  private findSelectedCurrentWorkingStatus(currentworkingStatuses: any[], objectToCompare) {
    return currentworkingStatuses.find(workingStatus => workingStatus.Task_Current_Working_Status_InfoID === objectToCompare.TaskCurrentWorkingStatusInfoID);
  }

  responsiblePersonIconClickEvent() {

    if (this.hasValue(this.taskEditInfoModel.TaskLatestStatusID) && this.taskEditInfoModel.TaskLatestStatusID != 1) {
      this.ShowErrorMessage('Not Allowed to Change the Responsible Person for On Going Task(s).');
      return;
    }

    const dialogRef = this.dialogService.open({
      title: 'Select Responsible Person',
      // Show component
      content: ResponsiblepersonUsersListComponent,

      height: 698,
      width: 550,
      appendTo: this.ContainerRef
    });
    // dialogRef.content.instance.StateMaintainUsersData = this.selectedObservers;
    dialogRef.result.subscribe((result) => {
      //debugger;
      var initial = this.initialResponsiblePerson
      this.previousResponsibleperson = initial;
      if (result instanceof DialogCloseResult) {
        if (this.hasValue(this.responsiblePerson)) {

        } else {
          this.responsiblePerson.EmployeeId = undefined;
          this.responsiblePerson.EmployeeFullName = '';
        }
      } else {
        var selectedResponsiblePersonInfo: any = result;

        if (this.hasValue(selectedResponsiblePersonInfo)) {

          this.responsiblePerson.EmployeeId = selectedResponsiblePersonInfo.EmployeeId;
          this.responsiblePerson.EmployeeFullName = selectedResponsiblePersonInfo.EmployeeFullName;

        }

        //console.log(this.initialResponsiblePerson);
        //console.log(this.previousResponsibleperson);
      }
    });
  }

  RemoveResponsiblePersonInfo() {
    this.responsiblePerson.EmployeeId = undefined;
    this.responsiblePerson.EmployeeFullName = '';
  }

  practiceIconClickEvent() {
    const dialogRef = this.dialogService.open({
      title: 'Select Practice',
      // Show component
      content: PraticesListComponent,
      height: 698,
      width: 550,
      appendTo: this.ContainerRef
    });
    dialogRef.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {

      } else {
        this.SelectedPracticeInfo = result;
        // if (this.hasValue(this.SelectedPracticeInfo)) {
        //   this.taskEditInfoModel.PracticeName = this.SelectedPracticeInfo.PracticeName;
        //   this.taskEditInfoModel.EHRPracticeID = this.SelectedPracticeInfo.PracticeId;
        // }
      }
    });
  }

  RemovePracticeInfo() {
    this.SelectedPracticeInfo.PracticeName = '';
    this.SelectedPracticeInfo.PracticeId = undefined;
  }

  // assistantPersonIconClickEvent() {
  //   const dialogRef = this.dialogService.open({
  //     title: 'Select Person to Forward Task',
  //     // Show component
  //     content: AdditionalParticipantAndObserversPopupComponentComponent,
  //     height: 720,
  //     width: 1140,
  //     appendTo: this.ContainerRef
  //   });
  //   dialogRef.content.instance.StateMaintainAddlResponsiblePersonsData = this.selectedAdditionalResponsiblePersons;
  //   dialogRef.content.instance.StateMaintainObserverPersonsData = this.selectedObservers;
  //   dialogRef.content.instance.StateMaintainAssistantPersonsData = this.SelectedAssistantPerson;
  //   dialogRef.result.subscribe((result) => {
  //     //debugger;
  //     if (result instanceof DialogCloseResult) {
  //       if (this.hasValue(this.SelectedAssistantPersonInfo)) {

  //       } else {
  //         this.SelectedAssistantPersonInfo = '';
  //       }
  //     } else {

  //       //assign popup result data to global variable
  //       this.PopupDataFromMultiSelectUserSelectionPopup = result;

  //       this.SelectedAssistantPersonInfo = this.PopupDataFromMultiSelectUserSelectionPopup.SelectedAssistantPersonsInfo;;
  //       this.IsAssistantPersonInfoChanged = true;
  //       this.SelectedAssistantPerson = this.PopupDataFromMultiSelectUserSelectionPopup.SelectedAssistantPersonsInfo;;
  //       if (this.hasValue(this.SelectedAssistantPersonInfo)) {
  //         this.SelectedAssistantPersonName = "";
  //         this.SelectedAssistantPersonInfo.forEach((selectedRow, index) => {
  //           this.SelectedAssistantPersonName += selectedRow.EmployeeFullName + ', ';
  //         });

  //         this.SelectedAssistantPersonName = this.SelectedAssistantPersonName.substring(0, this.SelectedAssistantPersonName.length - 2);
  //       }
  //       else {
  //         this.SelectedAssistantPersonName = "";
  //       }

  //       this.SelectedAdditionalResponsiblePersonInfo = this.PopupDataFromMultiSelectUserSelectionPopup.SelectedAddlResponsiblePersonsInfo;;
  //       this.IsAdditionalResponsiblePersonInfoChanged = true;
  //       this.selectedAdditionalResponsiblePersons = this.PopupDataFromMultiSelectUserSelectionPopup.SelectedAddlResponsiblePersonsInfo;;
  //       if (this.hasValue(this.SelectedAdditionalResponsiblePersonInfo)) {
  //         this.SelectedAdditionalResponsiblePersonName = "";
  //         this.SelectedAdditionalResponsiblePersonInfo.forEach((selectedRow, index) => {
  //           this.SelectedAdditionalResponsiblePersonName += selectedRow.EmployeeFullName + ', ';
  //         });

  //         this.SelectedAdditionalResponsiblePersonName = this.SelectedAdditionalResponsiblePersonName.substring(0, this.SelectedAdditionalResponsiblePersonName.length - 2);
  //       }
  //       else {
  //         this.SelectedAdditionalResponsiblePersonName = "";
  //       }


  //       this.SelectedObserversInfo = this.PopupDataFromMultiSelectUserSelectionPopup.SelectedObserversInfo;
  //       this.IsObserversInfoChanged = true;
  //       this.selectedObservers = this.PopupDataFromMultiSelectUserSelectionPopup.SelectedObserversInfo;
  //       if (this.hasValue(this.SelectedObserversInfo)) {
  //         this.SelectedObserversPersonNames = "";
  //         this.SelectedObserversInfo.forEach((selectedRow, index) => {
  //           this.SelectedObserversPersonNames += selectedRow.EmployeeFullName + ', ';
  //         });

  //         this.SelectedObserversPersonNames = this.SelectedObserversPersonNames.substring(0, this.SelectedObserversPersonNames.length - 2);
  //       }
  //       else {
  //         this.SelectedObserversPersonNames = "";
  //       }

  //     }
  //   });
  // }

  RemoveAssistantPersonInfo() {
    this.SelectedAssistantPersonName = "";
    this.SelectedAssistantPerson = undefined;
    this.IsresponsiblePersonAndFwdToSame = false;
  }




  TaskEditAdditionalResponsiblePersonIconClickEvent() {
    const dialogRef = this.dialogService.open({
      title: 'Select User(s)',
      // Show component
      content: AdditionalParticipantAndObserversPopupComponentComponent,
      // actions: [
      //   { text: 'OK', primary: true, width: 110 },
      //   { text: 'Cancel', width: 110 }
      // ],
      // width: '550px'
      height: 720,
      width: 1140,
      appendTo: this.ContainerRef

    });
    dialogRef.content.instance.StateMaintainAddlResponsiblePersonsData = this.selectedAdditionalResponsiblePersons;
    dialogRef.content.instance.StateMaintainObserverPersonsData = this.selectedObservers;
    dialogRef.content.instance.StateMaintainAssistantPersonsData = this.SelectedAssistantPerson;
    dialogRef.result.subscribe((result) => {
      //debugger;
      if (result instanceof DialogCloseResult) {
        if (this.hasValue(this.SelectedAdditionalResponsiblePersonInfo)) {

        } else {
          this.SelectedAdditionalResponsiblePersonInfo = '';
        }
      } else {

        //assign popup result data to global variable
        this.PopupDataFromMultiSelectUserSelectionPopup = result;

        this.SelectedAssistantPersonInfo = this.PopupDataFromMultiSelectUserSelectionPopup.SelectedAssistantPersonsInfo;;
        this.IsAssistantPersonInfoChanged = true;
        this.SelectedAssistantPerson = this.PopupDataFromMultiSelectUserSelectionPopup.SelectedAssistantPersonsInfo;;
        if (this.hasValue(this.SelectedAssistantPersonInfo)) {
          this.SelectedAssistantPersonName = "";
          this.SelectedAssistantPersonInfo.forEach((selectedRow, index) => {
            this.SelectedAssistantPersonName += selectedRow.EmployeeFullName + ', ';
          });

          this.SelectedAssistantPersonName = this.SelectedAssistantPersonName.substring(0, this.SelectedAssistantPersonName.length - 2);
        }
        else {
          this.SelectedAssistantPersonName = "";
        }

        this.SelectedAdditionalResponsiblePersonInfo = this.PopupDataFromMultiSelectUserSelectionPopup.SelectedAddlResponsiblePersonsInfo;;
        this.IsAdditionalResponsiblePersonInfoChanged = true;
        this.selectedAdditionalResponsiblePersons = this.PopupDataFromMultiSelectUserSelectionPopup.SelectedAddlResponsiblePersonsInfo;;
        if (this.hasValue(this.SelectedAdditionalResponsiblePersonInfo)) {
          this.SelectedAdditionalResponsiblePersonName = "";
          this.SelectedAdditionalResponsiblePersonInfo.forEach((selectedRow, index) => {
            this.SelectedAdditionalResponsiblePersonName += selectedRow.EmployeeFullName + ', ';
          });

          this.SelectedAdditionalResponsiblePersonName = this.SelectedAdditionalResponsiblePersonName.substring(0, this.SelectedAdditionalResponsiblePersonName.length - 2);
        }
        else {
          this.SelectedAdditionalResponsiblePersonName = "";
        }


        this.SelectedObserversInfo = this.PopupDataFromMultiSelectUserSelectionPopup.SelectedObserversInfo;
        this.IsObserversInfoChanged = true;
        this.selectedObservers = this.PopupDataFromMultiSelectUserSelectionPopup.SelectedObserversInfo;
        if (this.hasValue(this.SelectedObserversInfo)) {
          this.SelectedObserversPersonNames = "";
          this.SelectedObserversInfo.forEach((selectedRow, index) => {
            this.SelectedObserversPersonNames += selectedRow.EmployeeFullName + ', ';
          });

          this.SelectedObserversPersonNames = this.SelectedObserversPersonNames.substring(0, this.SelectedObserversPersonNames.length - 2);
        }
        else {
          this.SelectedObserversPersonNames = "";
        }
      }
    });
  }

  RemoveAdditionalResponsiblePersonInfo() {
    this.SelectedAdditionalResponsiblePersonName = "";
    this.selectedAdditionalResponsiblePersons = undefined;
    // this.IsresponsiblePersonAndFwdToSame = false;
  }



  ObserversIconClickEvent() {
    const dialogRef = this.dialogService.open({
      title: 'Select Users(s)',
      // Show component
      content: AdditionalParticipantAndObserversPopupComponentComponent,
      // actions: [
      //   { text: 'OK', primary: true, width: 110 },
      //   { text: 'Cancel', width: 110 }
      // ],
      // width: '550px'
      height: 720,
      width: 1140,
      appendTo: this.ContainerRef
    });
    dialogRef.content.instance.StateMaintainAddlResponsiblePersonsData = this.selectedAdditionalResponsiblePersons;
    dialogRef.content.instance.StateMaintainObserverPersonsData = this.selectedObservers;
    dialogRef.content.instance.StateMaintainAssistantPersonsData = this.SelectedAssistantPerson;
    dialogRef.result.subscribe((result) => {
      //debugger;
      if (result instanceof DialogCloseResult) {
        if (this.hasValue(this.SelectedObserversInfo)) {

        } else {
          this.SelectedObserversInfo = '';
        }
      } else {

        //assign popup result data to global variable
        this.PopupDataFromMultiSelectUserSelectionPopup = result;

        this.SelectedAssistantPersonInfo = this.PopupDataFromMultiSelectUserSelectionPopup.SelectedAssistantPersonsInfo;;
        this.IsAssistantPersonInfoChanged = true;
        this.SelectedAssistantPerson = this.PopupDataFromMultiSelectUserSelectionPopup.SelectedAssistantPersonsInfo;;
        if (this.hasValue(this.SelectedAssistantPersonInfo)) {
          this.SelectedAssistantPersonName = "";
          this.SelectedAssistantPersonInfo.forEach((selectedRow, index) => {
            this.SelectedAssistantPersonName += selectedRow.EmployeeFullName + ', ';
          });

          this.SelectedAssistantPersonName = this.SelectedAssistantPersonName.substring(0, this.SelectedAssistantPersonName.length - 2);
        }
        else {
          this.SelectedAssistantPersonName = "";
        }

        this.SelectedAdditionalResponsiblePersonInfo = this.PopupDataFromMultiSelectUserSelectionPopup.SelectedAddlResponsiblePersonsInfo;;
        this.IsAdditionalResponsiblePersonInfoChanged = true;
        this.selectedAdditionalResponsiblePersons = this.PopupDataFromMultiSelectUserSelectionPopup.SelectedAddlResponsiblePersonsInfo;;
        if (this.hasValue(this.SelectedAdditionalResponsiblePersonInfo)) {
          this.SelectedAdditionalResponsiblePersonName = "";
          this.SelectedAdditionalResponsiblePersonInfo.forEach((selectedRow, index) => {
            this.SelectedAdditionalResponsiblePersonName += selectedRow.EmployeeFullName + ', ';
          });

          this.SelectedAdditionalResponsiblePersonName = this.SelectedAdditionalResponsiblePersonName.substring(0, this.SelectedAdditionalResponsiblePersonName.length - 2);
        }
        else {
          this.SelectedAdditionalResponsiblePersonName = "";
        }


        this.SelectedObserversInfo = this.PopupDataFromMultiSelectUserSelectionPopup.SelectedObserversInfo;
        this.IsObserversInfoChanged = true;
        this.selectedObservers = this.PopupDataFromMultiSelectUserSelectionPopup.SelectedObserversInfo;
        if (this.hasValue(this.SelectedObserversInfo)) {
          this.SelectedObserversPersonNames = "";
          this.SelectedObserversInfo.forEach((selectedRow, index) => {
            this.SelectedObserversPersonNames += selectedRow.EmployeeFullName + ', ';
          });

          this.SelectedObserversPersonNames = this.SelectedObserversPersonNames.substring(0, this.SelectedObserversPersonNames.length - 2);
        }
        else {
          this.SelectedObserversPersonNames = "";
        }
      }
    });
  }

  RemoveObserversIconClickEvent() {
    this.SelectedObserversPersonNames = "";
    this.selectedObservers = undefined;
    // this.IsresponsiblePersonAndFwdToSame = false;
  }

  private sendInternalTicketNotificationtoEmployees(): void {
    if (this.hasValue(this.responsiblePerson) && this.hasValue(this.responsiblePerson.EmployeeId)) {
      if (this.stateMaintainresponsiblePerson == this.responsiblePerson.EmployeeId.toString()) return;
    }
    this._createNewTaskService.sendInternalTicketNotificationtoEmployees({
      //EmployeeId: this.responsiblePerson.EmployeeId,
      EmployeeIds: [this.responsiblePerson.EmployeeId,
        this.SelectedQAPersonInfo.EmployeeId,
        this.SelectedCSharpPersonInfo.EmployeeId,
        this.SelectedDBPersonInfo.EmployeeId,
        this.SelectedJSPersonInfo.EmployeeId].filter((value, index, self) => {
          return self.indexOf(value) === index;
        }),
      TaskNumber: this.TaskNumber,
      IssueSeverityID: (this.selectedSeverity) ? this.selectedSeverity.IssueSeverityID : 0,
    }).subscribe(serviceResponse => {
      if (this.isError(serviceResponse)) { return; }
    });
  }


    //#region  " QA "
    qaPersonIconClickEvent() {
      const dialogRef = this.dialogService.open({
        title: "Select QA Person",
        // Show component
        content: ResponsiblepersonUsersListComponent,
        // actions: [
        //   { text: 'OK', primary: true, width: 110 },
        //   { text: 'Cancel', width: 110 }
        // ],
        // width: '550px'
        height: 698,
        width: 550,
        appendTo: this.ContainerRef,
      });
      dialogRef.result.subscribe((result) => {
        //debugger;
        if (result instanceof DialogCloseResult) {
          if (this.hasValue(this.SelectedQAPersonInfo)) {
          } else {
            this.SelectedQAPersonInfo = "";
          }
        } else {
          this.SelectedQAPersonInfo = result;
          if (this.hasValue(this.SelectedQAPersonInfo)) {
            this.taskEditInfoModel.SelectedQAPerson =
              this.SelectedQAPersonInfo.EmployeeId;
            this.SelectedQAPersonInfo.EmployeeId =
              this.SelectedQAPersonInfo.EmployeeId;
            this.SelectedQAPersonInfo.EmployeeFullName =
              this.SelectedQAPersonInfo.EmployeeFullName;
          }
        }
      });
    }
  
    RemoveQAPersonInfo() {
      this.taskEditInfoModel.SelectedQAPerson = undefined;
      this.SelectedQAPersonInfo.EmployeeId = undefined;
      this.SelectedQAPersonInfo.EmployeeFullName = "";
    }
    //#endregion
  
  
    //#region  " C# "
    csharpPersonIconClickEvent() {
      const dialogRef = this.dialogService.open({
        title: "Select C# Person",
        // Show component
        content: ResponsiblepersonUsersListComponent,
        // actions: [
        //   { text: 'OK', primary: true, width: 110 },
        //   { text: 'Cancel', width: 110 }
        // ],
        // width: '550px'
        height: 698,
        width: 550,
        appendTo: this.ContainerRef,
      });
      dialogRef.result.subscribe((result) => {
        //debugger;
        if (result instanceof DialogCloseResult) {
          if (this.hasValue(this.SelectedCSharpPersonInfo)) {
          } else {
            this.SelectedCSharpPersonInfo = "";
          }
        } else {
          this.SelectedCSharpPersonInfo = result;
          if (this.hasValue(this.SelectedCSharpPersonInfo)) {
            this.taskEditInfoModel.SelectedCSharpPerson =
              this.SelectedCSharpPersonInfo.EmployeeId;
            this.SelectedCSharpPersonInfo.EmployeeId =
              this.SelectedCSharpPersonInfo.EmployeeId;
            this.SelectedCSharpPersonInfo.EmployeeFullName =
              this.SelectedCSharpPersonInfo.EmployeeFullName;
          }
        }
      });
    }
  
    RemoveCSharpPersonInfo() {
      this.taskEditInfoModel.SelectedCSharpPerson = undefined;
      this.SelectedCSharpPersonInfo.EmployeeId = undefined;
      this.SelectedCSharpPersonInfo.EmployeeFullName = "";
    }
    //#endregion
  
  
    //#region  " DB "
    dbPersonIconClickEvent() {
      const dialogRef = this.dialogService.open({
        title: "Select DB Person",
        // Show component
        content: ResponsiblepersonUsersListComponent,
        // actions: [
        //   { text: 'OK', primary: true, width: 110 },
        //   { text: 'Cancel', width: 110 }
        // ],
        // width: '550px'
        height: 698,
        width: 550,
        appendTo: this.ContainerRef,
      });
      dialogRef.result.subscribe((result) => {
        //debugger;
        if (result instanceof DialogCloseResult) {
          if (this.hasValue(this.SelectedDBPersonInfo)) {
          } else {
            this.SelectedDBPersonInfo = "";
          }
        } else {
          this.SelectedDBPersonInfo = result;
          if (this.hasValue(this.SelectedDBPersonInfo)) {
            this.taskEditInfoModel.SelectedDBPerson =
              this.SelectedDBPersonInfo.EmployeeId;
            this.SelectedDBPersonInfo.EmployeeId =
              this.SelectedDBPersonInfo.EmployeeId;
            this.SelectedDBPersonInfo.EmployeeFullName =
              this.SelectedDBPersonInfo.EmployeeFullName;
          }
        }
      });
    }
  
    RemoveDBPersonInfo() {
      this.taskEditInfoModel.SelectedDBPerson = undefined;
      this.SelectedDBPersonInfo.EmployeeId = undefined;
      this.SelectedDBPersonInfo.EmployeeFullName = "";
    }
    //#endregion
  
    //#region  " JS "
    jsPersonIconClickEvent() {
      const dialogRef = this.dialogService.open({
        title: "Select Angular JS Person",
        // Show component
        content: ResponsiblepersonUsersListComponent,
        // actions: [
        //   { text: 'OK', primary: true, width: 110 },
        //   { text: 'Cancel', width: 110 }
        // ],
        // width: '550px'
        height: 698,
        width: 550,
        appendTo: this.ContainerRef,
      });
      dialogRef.result.subscribe((result) => {
        //debugger;
        if (result instanceof DialogCloseResult) {
          if (this.hasValue(this.SelectedJSPersonInfo)) {
          } else {
            this.SelectedJSPersonInfo = "";
          }
        } else {
          this.SelectedJSPersonInfo = result;
          if (this.hasValue(this.SelectedJSPersonInfo)) {
            this.taskEditInfoModel.SelectedJSPerson =
              this.SelectedJSPersonInfo.EmployeeId;
            this.SelectedJSPersonInfo.EmployeeId =
              this.SelectedJSPersonInfo.EmployeeId;
            this.SelectedJSPersonInfo.EmployeeFullName =
              this.SelectedJSPersonInfo.EmployeeFullName;
          }
        }
      });
    }
  
    RemoveJSPersonInfo() {
      this.taskEditInfoModel.SelectedJSPerson = undefined;
      this.SelectedJSPersonInfo.EmployeeId = undefined;
      this.SelectedJSPersonInfo.EmployeeFullName = "";
    }
    //#endregion
  
}

// this.taskTypeList = [
//   {"TaskTypeDesc":"Issue","TaskTypeID":"0"},
//   {"TaskTypeDesc":"Bug","TaskTypeID":"1"},
// ]


export interface Employee {
  EmployeeId: string;
  EmployeeFullName: string;
}



