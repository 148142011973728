import { Component, OnInit, ViewChild } from "@angular/core";
import { commonHelperService } from "../common/common.service";
import { AgGridNg2 } from "ag-grid-angular";
import { GridOptions } from "ag-grid-community";
import { TaskService } from "../services/task.service";
import { DialogRef } from "@progress/kendo-angular-dialog";
import { commonhelper } from "../common/commonhelper";
import { LeftNavService } from "../common/Leftnav.service";
import { HttpClient } from "@angular/common/http";
import { IpopuModel } from "../practice-users-customization/practice-users-customization.interface";

@Component({
  selector: 'app-view-draft-comments',
  templateUrl: './view-draft-comments.component.html',
  styleUrls: ['./view-draft-comments.component.css']
})
export class ViewDraftCommentsComponent extends commonhelper implements OnInit  {

  public singleSelectioncolumnDefs;
  private commonhelperobj: commonHelperService;
  @ViewChild("usersgrid") usersgrid: AgGridNg2;
  @ViewChild("singleSelectionusersgrid") singleSelectionusersgrid: AgGridNg2;

  public practiceListGridOptions: GridOptions;
  public gridOptions: GridOptions;
  public gridApi;
  public gridColumnApi;
  public selectedRowsString: any;
  private fieldArray: Array<any> = [];
  public userIDs: any = [];
  public rowSelection;
  rowData: any = [];
  public usersModel: any = {};
  private editmodedata: any = {};
  public UsersList: Array<string> = [];
  public overlayNoRowsTemplate;
  public SearchBodyContent: any;
  public defaultColDef;
  public columnDefs;
  public SelectedUserIDs: any = "";
  public StateMaintainUsersData: any = [];
  public IsFromSingleSelectionToPopup: any = false;
  showSingleSelectionPopup = false;
  callLogSelecctedPracticeId = 0;
  datatoPopup: any;

  constructor(
    private taskService: TaskService,
    private dialog: DialogRef,
    private LeftNav: LeftNavService,
    private http: HttpClient
  ) {
    super();

    this.singleSelectioncolumnDefs = [
      // { headerName: "User ID", field: "UserID", width: 120 },
      {
        headerName: "Comments",
        field: "ViewContent",
        width: 500,
        // headerCheckboxSelection: true,
        // headerCheckboxSelectionFilteredOnly: true,
        // checkboxSelection: true,
        cellClass: "no-border cell-wrap-text",
        autoHeight: true,
      },
    ];
    // tslint:disable-next-line:max-line-length
    this.overlayNoRowsTemplate =
      "<span style='Font-size:16px;color:#00529B;background-color:#BDE5F8;margin: 50px 0px;padding: 12px;border-radius: 4px;'>Users does not Exist in this Practice.</span>";

 
    //this.defaultColDef = { width: 100 };
    this.rowData=[
      {bodyContent:"Fatta",ContentID:123456}
    ]
  }

  
  ngOnInit() {
    this.getDraftComments();
  }

  onSelectionChanged(event) {
    //alert("kk");
    this.fieldArray = [];
    // const selectedRows = this.usersgrid.api.getSelectedRows();
    this.fieldArray = this.usersgrid.api.getSelectedRows();
    // if (this.fieldArray) {
    //   this.userIDs = $.map(this.fieldArray, function (item) {
    //     return item;
    //   }).join(',');
    // }
  }


  onSingleSelectionChanged(event) {
    const selectedRows = this.singleSelectionusersgrid.api.getSelectedRows();
// console.log(selectedRows)
    selectedRows.forEach((selectedRow, index) => {
      //debugger;
      if (index !== 0) {
        this.selectedRowsString = {};
      }
      this.selectedRowsString = selectedRow;
      // console.log(this.selectedRowsString)
      // this.SelectedModuleInfo = selectedRow.Description;
    });
    // console.log(this.selectedRowsString);
  }

  onModelUpdated() {
    if (this.hasValue(this.StateMaintainUsersData)) {
      this.StateMaintainUsersData.forEach((selectedRow, index) => {        
        this.usersgrid.api.forEachNode((node) => {
          if (node.data.ContentID === parseInt(selectedRow.ContentID)) {
            node.setSelected(true);
            // console.log("model Updated")
          }
        });
      });
    }
  }

  assignSelectedInput() {
    if (!this.hasValue(this.selectedRowsString)) {
      this.ShowErrorMessage("Please Select User.");
      return false;
    }
    this.dialog.close(this.selectedRowsString);
  }

  OKClickEvent() {
    if (this.IsFromSingleSelectionToPopup) {
      this.assignSelectedInput();
      return;
    }
    if (!this.hasValue(this.selectedRowsString) && this.selectedRowsString.length <= 0) {
      this.ShowErrorMessage("Please Select User.");
      return false;
    }

    if (this.hasValue(this.selectedRowsString)) {  
      // console.log(this.selectedRowsString,"View Single") 
      let modifiedString = this.selectedRowsString.BodyContent.replace(/(<([^>]+)>)/ig, '');
        this.usersModel = {
          BodyContent: this.selectedRowsString.BodyContent,
          Signature:this.selectedRowsString.Signature,
          TaskCommentID: this.selectedRowsString.TaskCommentID,
          TaskInfoID: this.selectedRowsString.TaskInfoID,
        };
        // tslint:disable-next-line:no-shadowed-variable

        // const filteredListItem = this.grep(this.editmodedata.UsersArray, (itemInLoop) => {
        //   return itemInLoop.UserID === this.usersModel.UserID;
        // }, undefined);

        // this.editmodedata.UsersArray = [];
   
    }

    if (
      this.hasValue(this.usersModel)
    ) {
      // alert(this.editmodedata.UsersArray);
      // console.log(this.usersModel)
      this.dialog.close(this.usersModel);
      this.dialog.close();
    }
  }

  CancelClickEvent() {
    this.dialog.close();
  }

  getDraftComments() {
    let postData:any={};
    postData.TaskInfoID=this.datatoPopup.TaskInfoID,  
    this.taskService.draftCommentsList(postData).subscribe(response => {
      if (this.isError(response)) {
        return;
      }
    this.rowData =[]
     response.TaskCommentsDraftList.forEach(x=>{     

          x.ViewContent = x.BodyContent.replace(/(<([^>]+)>)/ig, '');
          this.rowData.push(x);
      });

      // console.log(this.rowData,"Data")
      // this.selectedRowsString = '';
      // if(isfrom)
      //  document.getElementById("divSearchPracticeName").focus();
    });
  }
}
