import { Component, OnInit, ViewChild } from "@angular/core";
import { commonhelper } from "../common/commonhelper";
import { TaskService } from "../services/task.service";
import { DialogService, DialogRef } from "@progress/kendo-angular-dialog";
import { commonHelperService } from "../common/common.service";
import { AgGridNg2 } from "ag-grid-angular";
import { GridOptions } from "ag-grid-community";
import { Observable } from "rxjs";
import { Config } from "../config";
import { map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: "app-select-module-popup-component",
  templateUrl: "./select-module-popup-component.component.html",
  styleUrls: ["./select-module-popup-component.component.css"],
})
export class SelectModulePopupComponentComponent
  extends commonhelper
  implements OnInit
{
  private commonhelperobj: commonHelperService;
  @ViewChild("ModulesListGrid") modulesListGrid: AgGridNg2;
  public responsiblePersonGridOptions: GridOptions;
  public gridApi;
  public gridColumnApi;
  public selectedRowsString: {};
  public SearchModuleName: any = "";
  public SelectedModuleInfo: any = "";
  public overlayNoRowsTemplate;
  public gridOptions: GridOptions;
  ModuleList: any = [];
  columnDefs = [
    //
    // { headerName: 'Employee ID', field: 'ModuleId', width: 0, cellClass: 'no-border cell-wrap-text', autoHeight: true, hide: true },
    {
      headerName: "Module",
      field: "IssueSupportGroupDesc",
      width: 490,
      cellClass: "no-border cell-wrap-text",
      autoHeight: true,
    },
  ];
  rowData = [];
  constructor(
    private httpClient: HttpClient,
    private taskService: TaskService,
    private dialogService: DialogService,
    private dialog: DialogRef
  ) {
    super();
    this.commonhelperobj = new commonHelperService(httpClient);
    this.overlayNoRowsTemplate =
      "<span style='Font-size:16px;color:#00529B;background-color:#BDE5F8;margin: 10px 0px;padding: 12px;border-radius: 4px;'>No Data Available</span>";
    this.gridOptions = <GridOptions>{
      context: {
        componentParent: this,
      },
    };
  }

  ngOnInit() {
    this.modulesList();
    // debugger;
    setTimeout(function () {
      document.getElementById("divSearchUser").focus();
    }, 10);
  }

  modulesList() {
    const PostDataToService = {
      // ModuleDescription: this.SearchModuleName,
    };
    return this.commonhelperobj
      .PostData(
        Config.OASAppnURL + "OASIssueSupportGroupsListView",
        PostDataToService
      )
      .subscribe((response) => {
        if (this.isError(response)) {
          return;
        }
        this.ModuleList = response;
        this.rowData = response;
        this.selectedRowsString = "";
      });
  }

  onclickFilter() {
    let TempModule: any[] = this.ModuleList;
    if (this.SearchModuleName.trim() !== "") {
      let FinalFIlter = TempModule.filter((x) =>
        x.IssueSupportGroupDesc.toLocaleLowerCase().includes(
          this.SearchModuleName.toLocaleLowerCase()
        )
      );
      this.rowData = FinalFIlter;
    } else {
      this.rowData = this.ModuleList;
    }
  }

  onSelectionChanged(event) {
    const selectedRows = this.modulesListGrid.api.getSelectedRows();

    selectedRows.forEach((selectedRow, index) => {
      //debugger;
      if (index !== 0) {
        this.selectedRowsString = {};
      }
      this.selectedRowsString = selectedRow;
      this.SelectedModuleInfo = selectedRow.IssueSupportGroupDesc;
    });
    // console.log(this.selectedRowsString);
  }

  OKClickEvent() {
    if (!this.hasValue(this.selectedRowsString)) {
      this.ShowErrorMessage("Please Select Module");
      document.getElementById("divSearchUser").focus();
      return false;
    }
    if (this.hasValue(this.selectedRowsString)) {
      setTimeout(() => {
        this.dialog.close(this.selectedRowsString);
      });
    }
  }

  CancelClickEvent() {
    this.dialog.close();
  }

  onSearchChanged() {
    this.modulesListGrid.api.setQuickFilter(this.SearchModuleName);
  }

}
