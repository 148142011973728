import { NgModule, Injectable } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes, CanActivate, CanActivateChild } from '@angular/router';

import { OasLoginComponentComponent } from './oas-login-component/oas-login-component.component';
import { OasSignupComponentComponent } from './oas-signup-component/oas-signup-component.component';
import { OasMainDashboardComponentComponent } from './oas-main-dashboard-component/oas-main-dashboard-component.component';
import { EmployeeInfoComponentComponent } from './employee-info-component/employee-info-component.component';
import { AddTaskInformationComponent } from './add-task-information/add-task-information.component';
import { ViewTaskInformationComponent } from './view-task-information/view-task-information.component';
import { ViewFullTaskInformationComponent } from './view-full-task-information/view-full-task-information.component';
import { EmployeeDashboardViewComponent } from './employee-dashboard-view/employee-dashboard-view.component';
import { ProjectHierarchyInfoComponent } from './project-hierarchy-info/project-hierarchy-info.component';
import { CreateNewTaskComponentComponent } from './create-new-task-component/create-new-task-component.component';
import { CommonMessageInfoComponent } from './common-message-info/common-message-info.component';
import { EditTaskInformationComponent } from './edit-task-information/edit-task-information.component';
import { TaskCustomizationInformationComponent } from './task-customization-information/task-customization-information.component';
import { UncustomizedTaskListComponent } from './uncustomized-task-list/uncustomized-task-list.component';
import { EdituncustomizedtasklistComponent } from './edituncustomizedtasklist/edituncustomizedtasklist.component';
// tslint:disable-next-line:max-line-length
import { AddTaskCustomizationBasedOnEmailAddressComponent } from './add-task-customization-based-on-email-address/add-task-customization-based-on-email-address.component';
// tslint:disable-next-line:max-line-length
import { ViewTaskInformationComponentMainComponent } from './view-task-information-component-main/view-task-information-component-main.component';
// import { NotAssignedEmailsToAnyoneComponent } from './not-assigned-emails-to-anyone/not-assigned-emails-to-anyone.component';
import { AllTasksSummaryByForwardedToMeBucketComponent } from './all-tasks-summary-by-forwarded-to-me-bucket/all-tasks-summary-by-forwarded-to-me-bucket.component';
import { ReportByResponsibleEmployeeComponent } from './report-by-responsible-employee/report-by-responsible-employee.component';
import { AccessInformationComponent } from './access-information/access-information.component';
import { ViewAccessInformationPopupComponent } from './view-access-information-popup/view-access-information-popup.component';
import { AddEmployeeInfoPopupComponent } from './add-employee-info-popup/add-employee-info-popup.component';
import { EditAccessDetailsPopupComponent } from './edit-access-details-popup/edit-access-details-popup.component';
import { MonthSummaryReportComponent } from './month-summary-report/month-summary-report.component';
import { ByStatusComponent } from './by-status/by-status.component';
import { ByCustomerComponent } from './by-customer/by-customer.component';
import { ByImplementerComponent } from './by-implementer/by-implementer.component';
import { ByRepsonsiblePersonComponent } from './by-repsonsible-person/by-repsonsible-person.component';
import { ByMajorTopicsComponent } from './by-major-topics/by-major-topics.component';
import { BySubTopicsComponent } from './by-sub-topics/by-sub-topics.component';
import { MyAppointmentsComponent } from './my-appointments/my-appointments.component';
import { AddEditAppointmentsPopupComponent } from './add-edit-appointments-popup/add-edit-appointments-popup.component';
import { CustomerTicketsComponent } from './customer-tickets/customer-tickets.component';
import { PracticeStatusCheckListComponent } from './practice-status-check-list/practice-status-check-list.component';
import { CheckListItemComponent } from './check-list-item/check-list-item.component';
import { PracticeStatusCheckListItemsPopupComponent } from './practice-status-check-list-items-popup/practice-status-check-list-items-popup.component';
import { AddPracticeCheckListItemPopupComponent } from './add-practice-check-list-item-popup/add-practice-check-list-item-popup.component';
import { PracticeStatusCheckListItemHistoryPopupComponent } from './practice-status-check-list-item-history-popup/practice-status-check-list-item-history-popup.component';
import { AddPracticeCheckListItemCommentsPopupComponent } from './add-practice-check-list-item-comments-popup/add-practice-check-list-item-comments-popup.component';
import { AddPracticeCheckListItemStatusPopupComponent } from './add-practice-check-list-item-status-popup/add-practice-check-list-item-status-popup.component';
import { EasyFormTaskReportComponent } from './easy-form-task-report/easy-form-task-report.component';
import { AllCustomerTicketsComponent } from './all-customer-tickets/all-customer-tickets.component';
import { AllAppointmentsComponent } from './all-appointments/all-appointments.component';
import { TicketInformationComponent } from './ticket-information/ticket-information.component';
import { UserSelectionLoginPageComponent } from './user-selection-login-page/user-selection-login-page.component';
import { SupportEmployeeLoginPageComponent } from './support-employee-login-page/support-employee-login-page.component';
import { CustomerAddTicketPopupComponent } from './customer-add-ticket-popup/customer-add-ticket-popup.component';
import { CustomerCallLogComponent } from './customer-call-log/customer-call-log.component';
import { OverDueReportComponent } from './over-due-report/over-due-report.component';
import { EmployeesCustomizationComponent } from './employees-customization/employees-customization.component';
import { PracticeUsersCustomizationComponent } from './practice-users-customization/practice-users-customization.component';
import { StatisticsReportComponent } from './statistics-report/statistics-report.component';
import { NotRespondedTicketsReportListComponent } from './not-responded-tickets-report-list/not-responded-tickets-report-list.component';
import { AllBucketsConsolidateReportComponent } from './all-buckets-consolidate-report/all-buckets-consolidate-report.component';
import { ViewConsolidatedTaskdetailsComponent } from './view-consolidated-taskdetails/view-consolidated-taskdetails.component';
import { SupportStatisticsMainViewComponent } from './support-statistics-main-view/support-statistics-main-view.component';
import { TicketMonitoringReportComponent } from './ticket-monitoring-report/ticket-monitoring-report.component';
import { CustomerTicletsCountByPracticeWiseComponent } from './customer-ticlets-count-by-practice-wise/customer-ticlets-count-by-practice-wise.component';

const routes: Routes = [
    {
        path: 'home', component: OasMainDashboardComponentComponent, children: [
            { path: 'app-employee-info-component', component: EmployeeInfoComponentComponent, outlet: 'main', },
            { path: 'app-add-task-information', component: AddTaskInformationComponent, outlet: 'main' },
            { path: 'app-view-task-information/:info', component: ViewTaskInformationComponent, outlet: 'main' },
            { path: 'app-view-full-task-information/:info', component: ViewFullTaskInformationComponent, outlet: 'main' },
            { path: 'app-employee-dashboard-view', component: EmployeeDashboardViewComponent, outlet: 'main' },
            { path: 'app-project-hierarchy-info', component: ProjectHierarchyInfoComponent, outlet: 'main' },
            { path: 'app-create-new-task-component', component: CreateNewTaskComponentComponent, outlet: 'main' },
            { path: 'app-common-message-info/:info', component: CommonMessageInfoComponent, outlet: 'main' },
            { path: 'app-edit-task-information/:info', component: EditTaskInformationComponent, outlet: 'main' },
            { path: 'app-task-customization-information', component: TaskCustomizationInformationComponent, outlet: 'main' },
            { path: 'app-uncustomized-task-list', component: UncustomizedTaskListComponent, outlet: 'main' },
            { path: 'app-edituncustomizedtasklist/:info', component: EdituncustomizedtasklistComponent, outlet: 'main' },
            // tslint:disable-next-line:max-line-length
            { path: 'app-add-task-customization-based-on-email-address', component: AddTaskCustomizationBasedOnEmailAddressComponent, outlet: 'main' },
            { path: 'app-view-task-information-component-main', component: ViewTaskInformationComponentMainComponent, outlet: 'main' },
            // { path: 'app-not-assigned-emails-to-anyone', component: NotAssignedEmailsToAnyoneComponent, outlet: 'main' },
            { path: 'all-tasks-summary-by-forwarded-to-me-bucket.component', component: AllTasksSummaryByForwardedToMeBucketComponent, outlet: 'main' },
            { path: 'report-by-responsible-employee.component', component: ReportByResponsibleEmployeeComponent, outlet: 'main' },
            { path: 'access-information.component/:info', component: AccessInformationComponent, outlet: 'main' },
            { path: 'view-access-information-popup.component', component: ViewAccessInformationPopupComponent, outlet: 'main' },
            { path: 'add-employee-info-popup.component', component: AddEmployeeInfoPopupComponent, outlet: 'main' },
            { path: 'edit-access-details-popup.component', component: EditAccessDetailsPopupComponent, outlet: 'main' },
            { path: 'month-summary-report.component', component: MonthSummaryReportComponent, outlet: 'main' },
            { path: 'by-status.component/:info', component: ByStatusComponent, outlet: 'main' },
            { path: 'by-customer.component/:info', component: ByCustomerComponent, outlet: 'main' },
            { path: 'by-implementer.component/:info', component: ByImplementerComponent, outlet: 'main' },
            { path: 'by-repsonsible-person.component/:info', component: ByRepsonsiblePersonComponent, outlet: 'main' },
            { path: 'by-major-topics.component/:info', component: ByMajorTopicsComponent, outlet: 'main' },
            { path: 'by-sub-topics.component/:info', component: BySubTopicsComponent, outlet: 'main' },
            { path: 'my-appointments.component', component: MyAppointmentsComponent, outlet: 'main' },
            { path: 'add-edit-appointments-popup.component', component: AddEditAppointmentsPopupComponent, outlet: 'main' },
            { path: 'customer-tickets.component/:info', component: CustomerTicketsComponent, outlet: 'main' },
            { path: 'practice-status-check-list.component', component: PracticeStatusCheckListComponent, outlet: 'main' },
            { path: 'check-list-item.component', component: CheckListItemComponent, outlet: 'main' },
            { path: 'practice-status-check-list-items-popup.component', component: PracticeStatusCheckListItemsPopupComponent, outlet: 'main' },
            { path: 'add-practice-check-list-item-popup.component', component: AddPracticeCheckListItemPopupComponent, outlet: 'main' },
            { path: 'practice-status-check-list-item-history-popup.component', component: PracticeStatusCheckListItemHistoryPopupComponent, outlet: 'main' },
            { path: 'add-practice-check-list-item-comments-popup.component', component: AddPracticeCheckListItemCommentsPopupComponent, outlet: 'main' },
            { path: 'add-practice-check-list-item-status-popup.component', component: AddPracticeCheckListItemStatusPopupComponent, outlet: 'main' },
            { path: 'easy-form-task-report.component/:info', component: EasyFormTaskReportComponent, outlet: 'main' },
            { path: 'all-customer-tickets.component/:info', component: AllCustomerTicketsComponent, outlet: 'main' },
            { path: 'all-appointments.component/:info', component: AllAppointmentsComponent, outlet: 'main' },
            { path: 'ticket-information.component', component: TicketInformationComponent, outlet: 'main' },
            { path: 'user-selection-login-page.component', component: UserSelectionLoginPageComponent, outlet: 'main' },
            { path: 'support-employee-login-page.component', component: SupportEmployeeLoginPageComponent, outlet: 'main' },
            { path: 'customer-add-ticket-popup.component', component: CustomerAddTicketPopupComponent, outlet: 'main' },
            { path: 'customer-call-log.component/:info', component: CustomerCallLogComponent, outlet: 'main' },
            { path: 'over-due-report.component/:info', component: OverDueReportComponent, outlet: 'main' },
            { path: 'employees-customization.component', component: EmployeesCustomizationComponent, outlet: 'main' },
            { path: 'practice-users-customization.component', component: PracticeUsersCustomizationComponent, outlet: 'main' },
            { path: "statistics-report.component/:info",component:StatisticsReportComponent,outlet: 'main'},
            { path: 'not-responded-tickets-report-list.component/:info',component:NotRespondedTicketsReportListComponent,outlet:'main'}
           ,{path:'consolidated-tickets-report.component/:info',component:AllBucketsConsolidateReportComponent,outlet:'main'}
          ,{path:'view-consolidated-taskdetails.component/:info',component:ViewConsolidatedTaskdetailsComponent,outlet:'main'}
          ,{path:'support-statistics-main-view.component/:info',component:SupportStatisticsMainViewComponent,outlet:'main'}
          ,{path:'ticket-monitoring-report.component/:info',component:TicketMonitoringReportComponent,outlet:'main'}
          ,{path:'customer-ticlets-count-by-practice-wise.component/:info',component:CustomerTicletsCountByPracticeWiseComponent,outlet:'main'}

          ]

    },
    { path: 'Signup', component: OasSignupComponentComponent },
    { path: 'Login', component: OasLoginComponentComponent, pathMatch: 'full', }, // canActivate: [AuthGuard],
    { path: '', component: UserSelectionLoginPageComponent, pathMatch: 'full', },
    { path: 'SupportEmployeeLogin', component: SupportEmployeeLoginPageComponent, pathMatch: 'full', },
    // { path: '', component: this.myItem, pathMatch: 'full', canActivate: this.LoadSync() },

];



@NgModule({
    imports: [
        CommonModule,
        RouterModule.forRoot(routes, { useHash: true })
    ],
    declarations: [],
    exports: [
        RouterModule,
    ]
})
export class AppRoutingModule {

}
